*:focus {
    outline: none;
}

input {
    font-size: 1.7rem !important;
}

label {
    font-size: 1.7rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

@media only screen and (min-width: 1004px) {
    .MuiContainer-maxWidthLg {
        max-width: 1054px !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

// Fixes for material theme
// Fix for long labels bug
// https://github.com/mui-org/material-ui/issues/12255
.MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
}

.MuiFormHelperText-contained {
    margin: 0 !important;
}

.MuiFormControl-root {
    margin-bottom: 12px;
}

.Mui-disabled {
    cursor: default;
}

.MuiFilledInput-root.Mui-disabled,
textarea[disabled] {
    pointer-events: none;
    opacity: 0.4;

}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    background-color: rgba(0, 0, 0, 0.06);
}

.MuiFilledInput-underline:after {
    border-bottom-width: 1px !important;
}

.Mui-focused.MuiFilledInput-underline:after {
    border-bottom-color: var(--palette-primary-A500) !important;
    transform: scaleX(1);
}

.Mui-focusVisible .MuiIconButton-label span {
    border: 1px var(--palette-secondary-dark) solid !important;
}

.MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorSecondary.MuiFilledInput-colorSecondary {
    color: var(--palette-light) !important;
    background-color: var(--palette-secondary-input) !important;

    &:hover {
        background-color: var(--palette-secondary-inputHover) !important;
    }
}

.MuiFormLabel-root.MuiFormLabel-colorSecondary.Mui-focused.MuiInputLabel-filled,
.MuiFormLabel-colorSecondary.Mui-focused {
    color: var(--palette-light) !important;
}

.MuiFormLabel-root.MuiInputLabel-filled,
.MuiFormLabel-root.MuiFormLabel-colorSecondary.MuiInputLabel-filled {
    margin-left: 0 !important;
    line-height: 1.1;
}


.MuiInputAdornment-positionEnd {
    svg {
        width: 24px;
        height: 24px;
    }
}

.MuiFilledInput-adornedEnd {
    padding-right: 4px !important;
}

.MuiIconButton-edgeEnd {
    margin-right: 0 !important;
}

.api-key-styled {
    .MuiFormLabel-root {
        margin-left: 0;
    }

    .MuiFormLabel-root.Mui-error,
    .MuiFormHelperText-root.Mui-error {
        color: var(--palette-text-primary);
    }

    .MuiInput-underline.Mui-error:after {
        border-bottom-color: var(--palette-text-primary);
    }
}

.filled-form-control {
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-filled {
        color: var(--palette-text-primary) !important;
    }

    .MuiInputBase-root.MuiFilledInput-root {
        color: var(--palette-text-primary) !important;
    }
}

.MuiFormHelperText-root {
    font-size: 15px !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 8px) scale(0.85) !important;
}

.MuiInputLabel-filled {
    text-align: left;
}

.global-input-wrapper {
    min-height: 80px;
}


textarea {
    font-size: 17px;
    transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.09);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 57px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 18px 16px 16px;
    font-family: Source Sans Pro, sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    resize: vertical;

    &:hover {
        background-color: rgba(0, 0, 0, 0.13);
    }

    &:focus {
        border-bottom-color: var(--palette-primary-main);
    }
}

.MuiInputBase-root textarea {
    &:hover {
        background-color: transparent;
    }
}


// Narrow input
.narrow-input-wrapper {
    .global-input-wrapper {
        min-height: 73px;
    }

    label {
        font-size: 1.7rem !important;
        line-height: 1.2;
    }

    .MuiFilledInput-input {
        padding: 20px 12px 8px !important;
        border-radius: 5px 5px 0 0;
    }

    .MuiInputLabel-filled {
        transform: translate(12px, 14px) scale(1);
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(12px, 3px) scale(0.7) !important;
    }

    .MuiFormHelperText-root {
        font-size: 15px !important;
    }

    .MuiInputBase-input::-webkit-input-placeholder {
        font-size: 1.7rem !important;
    }
    .MuiFilledInput-underline.Mui-error:after,
    .MuiFilledInput-colorSecondary.MuiFilledInput-underline:after {
        transform: scale(1);
        border-bottom-color: #647185;
    }
    .MuiInputAdornment-positionEnd svg {
        width: 18px;
        height: 18px;
    }
}
