.splash-screen {
    // background-image is set up in index.scss of the concrete project
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
        // background-image is set up in index.scss of the concrete project
        min-width: 44rem;
        min-height: 12.8rem;
        background-repeat: no-repeat;
        margin-top: 21.3rem;
        padding-right: 1rem;
    }
}
