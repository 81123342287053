.label-paper-inner {
	width: calc(100% + 64px) !important;
	margin: 0 -32px;
}
.component-label-paper {
	min-width: 200px;
	margin-left: 32px;
	margin-right: 32px;
	transform: translateY(-50%);
	display: table;
	min-height: 48px;
	margin-bottom: -12px;
	background-color: var(--palette-secondary-main) !important;
}
.component-label-paper-title {
	padding: 14px 24px;
	text-align: center;
	color: #fff;
	font-size: 20px !important;
	line-height: 1 !important;
	letter-spacing: 0.25px !important;
	font-weight: 700 !important;
}
.paper-unstyled.MuiPaper-root {
	box-shadow: none !important;
}
.paper-inner {
	.paper-unstyled {
		.paper-inner {
			width: calc(100% + 64px);
    		margin: 0 -32px;
			.composite-label-paper-content {
				margin: 0 !important;
				.admin-import-table {
					margin: 0 !important;
					width: auto !important;
				}
			}
		}
	}
}

