.styled-nav,
.MuiDialogContent-root {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: rgba(109, 109, 109, 0.5);
    }

    &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background-color: rgba(1, 16, 32, 0.7);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: rgba(245, 245, 245, 0.5);
    }
}
// .MuiDialogContent-root {
//     background-color: rgb(1, 16, 32);
// }
