

.fade-in {
    opacity: 1;
    height: auto;
    -webkit-animation: fadeIn 1s;
}

.fade-out {
    transition: opacity 4s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    -webkit-animation: fadeOut 1s;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
