.white-background {
	.form-control {
		margin-left: 0 !important;
	}
	div, p, span:not(.required-question-mark), label {
		color: var(--palette-text-primary) !important;
	}
	.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary,
	.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
		color: var(--palette-text-primary) !important;
	}
	.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary svg {
		border-color: var(--palette-text-primary) !important;
	}
	.Mui-disabled {
		color: var(--palette-text-disabled) !important;
		border-color: var(--palette-text-disabled) !important;
		* {
			color: var(--palette-text-disabled) !important;
			border-color: var(--palette-text-disabled) !important;
		}
		& + .MuiFormControlLabel-label {
			color: var(--palette-text-disabled) !important;
			* {
				color: var(--palette-text-disabled) !important;
			}
		}
		&.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary svg {
			border-color: var(--palette-text-disabled) !important;
		}
	}

	.admin-question-description {
		*,
		div, p, span:not(.required-question-mark), label,
		.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary,
		.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
			color: var(--palette-secondary-main) !important;
		}
	}

}
