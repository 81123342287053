
.zoom-overlay-open .mainBlock,
.zoom-overlay-open .menuButton {
    z-index: auto !important;
}

.zoom-img,
.zoom-img-wrap {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 auto !important;
}

img.zoom-img {
    border-radius: inherit !important;
}

.zoom-overlay-open div:not(.zoom-img-wrap) {
    pointer-events: none
}