.render-questions-list > div:first-child {
    .questionWrapper {
        margin-top: 32px;
    }
}

.surveyDescription {
    width: calc(100% + 20px) !important;
    padding: 10px;
    margin: 0 -10px;
    background-color: var(--palette-primary-A200);
    @media only screen and (max-width: 960px) {
    }
}

.surveyBoxContainer {
    .surveyDescription {
        width: 100% !important;
        margin: 0 auto;
        @media only screen and (max-width: 960px) {
            width: 100% !important;
        }
    }
}
.questionWrapper .Mui-error {
    color: #D81C10 !important;
    font-weight: 500;
    letter-spacing: 0.1px;
    &.MuiInputBase-multiline {
        font-size: 16px !important;
    }
}

.errorWrapper,
.questionWrapper .MuiFormHelperText-root.Mui-error {
    background: transparent !important;
    color: var(--palette-error-main) !important;
    color: #D81C10 !important;
    padding: 2px 0 !important;
    min-height: 20px;
    font-weight: 500;
    font-size: 12px !important;
    display: block;
    letter-spacing: 0.1px;
    margin: 0;

    &:before {
        content: "";
        width: 5000px;
        height: 15000px;
        background: #ffecec;
        display: block;
        position: absolute;
        top: -2500px;
        left: -2500px;
        z-index: -1;
    }
}


.questionWrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    // margin: 0 0 32px;
    width: 100%;
    padding: 10px;
    border: 2px var(--palette-primary-A100) solid;
    @media only screen and (max-width: 960px) {
        // margin: 0 0 32px;
        width: 100%;
        padding: 10px;
    }
    .MuiFormControl-root {
        position: initial !important;
    }
    &.questionWrapperDropdown {
        z-index: 2;
        &.questionWrapper-error {
            background: #ffecec;
            z-index: 2;
            .errorWrapper,
            .MuiFormHelperText-root.Mui-error {
                &:before {
                    display: none;
                }
            }
        }
    }
}
