.circle-wrap {
  margin: 0 auto 10px;
  width: 50px;
  height: 50px;
  background: #d4e1f7;
  border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 50px, 50px, 25px);
}

.circle-wrap .circle .mask .fill {
  clip: rect(0px, 25px, 50px, 0px);
  background-color: var(--palette-primary-main);
}

.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
  animation: fill1 ease-in-out 3s;
  transform: rotate(45deg);
}
.circle-wrap-2 .circle .mask.full,
.circle-wrap-2 .circle .fill {
  animation: fill2 ease-in-out 3s;
  transform: rotate(90deg);
}
.circle-wrap-3 .circle .mask.full,
.circle-wrap-3 .circle .fill {
  animation: fill3 ease-in-out 3s;
  transform: rotate(135deg);
}
.circle-wrap-4 .circle .mask.full,
.circle-wrap-4 .circle .fill {
  animation: fill4 ease-in-out 3s;
  transform: rotate(180deg);
}
@keyframes fill1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes fill2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes fill3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
@keyframes fill4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.circle-wrap .inside-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--palette-light);
  line-height: 40px;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  z-index: 1;
  font-weight: 700;
  font-size: 16px;
}

.stepper-register-wrapper {
  position: relative;
  padding: 0 0 8px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (min-device-width: 490px) {
    .stepper-register-next {
      text-align: right;
      margin-right: 0;
      order: 3;
      margin-top: 16px;
      width: 130px;
      min-width: 130px;
    }
    .stepper-register-step {
      order: 2;
    }
    .stepper-register-back {
      text-align: left;
      margin-left: 0;
      order: 1;
      margin-top: 16px;
      width: 130px;
      min-width: 130px;
    }
  }
  @media screen and (max-device-width: 490px) {
    flex-wrap: wrap;
  }
  .stepper-register-title {
    opacity: 0.7;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2.08px;
    margin-bottom: 3px;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%;
  }
}

.stepper-register-step {
  margin-top: 11px;
}
@media only screen and (max-device-width: 490px) {
  .stepper-register-step,
  .stepper-register-back,
  .stepper-register-next {
    text-align: center;
    width: 100%;
  }

  .stepper-register-wrapper .stepper-register-title {
    position: relative;
    bottom: auto;
    left: auto;
  }
}
@media only screen and (max-device-width: 600px) {
  .stepper-register-back,
  .stepper-register-next {
    text-align: center;
  }
  .stepper-register-next {
    margin-bottom: 8px;
  }
  .stepper-register-back {
    margin-top: 16px;
  }

  .stepper-register-wrapper .stepper-register-title {
    position: relative;
    bottom: auto;
    left: auto;
  }
}
.stepper-register-only-button {
  width: 100%;
}
.stepper-register-wrapper-fullwidth {
  flex-wrap: wrap;
  justify-content: center;
}
