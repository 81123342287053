.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary,
.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
    color: var(--palette-light) !important;
}
.MuiButtonBase-root.MuiRadio-root + .MuiFormControlLabel-label {
    padding: 3px !important;
}
.radio-big {
    &.MuiFormControlLabel-root {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .MuiFormControlLabel-label.MuiTypography-body1 {
        font-size: 18px;
        font-weight: 400;
    }
    .MuiRadio-root .MuiSvgIcon-root {
        width: 22px;
        height: 22px;
    }
}
