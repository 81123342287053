.input-with-tooltip {
	position: relative;
	.MuiFilledInput-adornedEnd {
		padding-right: 46px !important;
	}
	.input-with-tooltip-svg {
		position: absolute;
		top: 24px;
		right: 25px;
	}
	svg {
		width: 24px;
		height: 24px;
	}
}
