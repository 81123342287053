.status-cell {
	svg {
		font-size: 32px;
		width: 32px;
		height: 32px;
		// @media only screen and (max-device-width: 767px) {
		// 	font-size: 20px;
		// 	width: 20px;
		// 	height: 20px;
		// }
	}
}
.admin-fullheight-wrapper {
    padding: 3% 7% 30px;
    height: calc(100vh - 64px);
}
.admin-table-fullheight {
	height: calc(90vh - 312px);
	min-height: 300px;
	margin: 0 -32px;
	> table {
		width: 100% !important;
		margin: 0 !important;
		thead tr th:last-child, 
		tbody tr td:last-child {
			padding-right: 15px !important;
		}
	}
	.MuiTableCell-root {
		padding-top: 8px !important;
		padding-right: 8px !important;
		padding-bottom: 8px !important;
	}

	.MuiTableCell-stickyHeader {
		border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
	}
}
.admin-table-fullheight.admin-table-fullheight-long {
	height: calc(90vh - 99px);
}
.admin-table-progress {
	width: 100%;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
}
