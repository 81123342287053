.MuiIconButton-root.MuiCheckbox-root {
    margin: -7px 5px 7px -7px;
    border-radius: 3px;
    width: 35px;
    height: 35px;
    padding: 9px;
    svg {
        fill: var(--palette-light);
        border: 1px rgba(0, 0, 0, 0.54) solid;
        width: 20px !important;
        height: 20px !important;
        font-size: 24px;
        border-radius: 3px !important;
        path {
            display: none;
        }
    }
    &.Mui-checked {
        svg {
            margin-top: -3px;
            margin-left: 1px;
            fill: var(--palette-primary-main);
            width: 27px !important;
            height: 27px !important;
            border: none;
            path {
                display: block;
            }
        }
    }
    &.Mui-disabled {
        svg {
            fill: rgba(206, 217, 224, 0.5) !important;
            cursor: not-allowed;
            border-color: var(--palette-action-disabled);
        }
    }
    &.Mui-disabled+.MuiFormControlLabel-label {
        color: rgba(0, 0, 0, 0.38);
    }
    .MuiTouchRipple-root {
        overflow: visible;
    }
    &.MuiCheckbox-colorSecondary {
        svg {
            border: 1px var(--palette-light) solid;
        }
        &.Mui-checked svg {
            fill: var(--palette-light);
            border: 1px transparent solid;
            width: 28px !important;
            height: 28px !important;
        }
    }
}

.MuiCheckbox-root.Mui-disabled+.MuiFormControlLabel-label {
    color: rgba(0, 0, 0, 0.38);
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
    .MuiIconButton-root.MuiCheckbox-root {
        margin: -7px 5px 7px 5px;
    }
}