#chartWrapper {
    display: block;
    width: calc(100vw - 30px);
    overflow: auto;
    white-space: nowrap;
    @media only screen and (max-device-width: 960px) {
        width: 100%;
        margin: 0 -16px;
        padding: 0;
    }
}
.chart-wrapper {
    position: relative;
}
[id^="chart"] svg,
#chart svg {
    overflow: visible;
}

@keyframes pulse-ring {
    0% {
        opacity: 0;
        r: 0;
    }
    100% {
        opacity: 0.3;
        r: 30;
    }
}

.circle-pulse {
    opacity: 0.3;
    transform: translate(40, 30);
    animation: pulse-ring 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.x-top-info .tick {
    position: relative;
}

.boxScoreLabel {
    transition: all 0.3s ease;
    color: var(--palette-light);
    background-color: var(--palette-primary-main);
    height: 50px;
    min-width: 50px;
    padding: 0 5px;
    border-radius: 8px;
    margin: 0 auto;
}

.boxDueLabel {
    transition: all 0.3s ease;
    color: var(--palette-light);
    background-color: #3b3c43;
    height: 50px;
    min-width: 57px;
    padding: 0 5px;
    border-radius: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .material-icons {
        margin: -4px auto 0;
        color: #5c5d65;
        font-size: 44px;
    }
    .boxDueLabelDate {
        width: 44px;
        margin-left: -44px;
        padding: 1px 2px 0 0;
        font-size: 11px;
        color: white;
    }
}

.xTopLabel {
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    margin: 0 auto;
}

.labelHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.xTopLabelText {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font: normal normal 600 12px/18px Source Sans Pro;
    margin: 0 auto 4px;

    &:hover {
        .labelHeaderUpcoming {
            &::before {
                content: "i";
                display: block;
                position: absolute;
                left: -20px;
                border-radius: 20px;
                width: 15px;
                height: 15px;
                color: #fff;
                background: #3a86ff;
                line-height: 15px;
                font-weight: 600;
            }
        }
    }
}

.dateLabel {
    white-space: nowrap;
    display: block;
}

.xTopLabelFuture {
    .boxDueLabel {
        background-color: #1f2029;
        .material-icons {
            color: #383841;
        }
    }
    .xTopLabelText {
        color: #a1a1a1 !important;
    }
}

@media only screen and (max-device-width: 960px) {
    [id^="chart"],
    #chart {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 960px) {
    [id^="chart"],
    #chart {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-device-width: 568px) {
    [id^="chart"],
    #chart {
        padding-left: 0;
    }
}

@media only screen and (max-width: 568px) {
    [id^="chart"],
    #chart {
        padding-left: 0;
    }
}

.chartGraph {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 15px;
        background-color: #fff;
    }
    &::-webkit-scrollbar {
        height: 15px;
        border-radius: 15px;
        background-color: #1f2029;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 2px #fff solid;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: #383841;
    }
}

// Focus style on black background
.rootChart {
    .Mui-focusVisible:not(.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button) {
        outline: 2px #fff solid !important;
    }
}

.chart-side-info {
    padding: 40px 20px;
    margin-bottom: 6px !important;
    @media only screen and (min-device-width: 960px) {
        padding: 10px 10px 40px 2.8%;
        border-left: 1px solid #d7d7d7;
        box-shadow: inset 1px 0 3px -2px rgba(0,0,0,0.4);
    }
    
    @media only screen and (min-width: 960px) {
        padding: 10px 10px 40px 2.8%;
        border-left: 1px solid #d7d7d7;
        box-shadow: inset 1px 0 3px -2px rgba(0,0,0,0.4);
    }
}
.gridGroup,
.gridGroupY  {
    > .domain {
        display: none;
    }
    .tick:first-of-type line{
        fill: none;
        stroke: none;
    }
}

.lineChart {
    .xAxisGroup > .tick:first-of-type {
        transform: translateX(10px);
    }
    .xAxisGroup > .tick:last-of-type {
        transform: translateX(calc(100% - 10px));
    }
    .xAxisGroup > .tick:first-of-type:last-of-type {
        transform: translateX(50%);
    }
}
.lineChart {
    .xAxisGroup > .tick.tickLong:first-of-type {
        transform: translateX(50px);
    }
    .xAxisGroup > .tick.tickLong:last-of-type {
        transform: translateX(calc(100% - 44px));
    }
}

.lineChart {
    circle, 
    foreignObject, 
    div, 
    span {
        -webkit-tap-highlight-color: transparent;
    }
}
