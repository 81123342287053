.graph-settings-drawer {
	.MuiInputBase-root {
		font-size: 16px;
	}
	.MuiFilledInput-underline:after,
	.MuiInput-underline:after,
	.MuiInput-underline:before {
		display: none;
	}
	.MuiSelect-select {
		border-bottom: solid 1px #979797;
		&:focus {
			background: transparent;
		}
	}

	.MuiTextField-root.MuiFormControl-root:not(fieldset) {
		border-radius: 4px 4px 0 0 !important;
		background: #ebebeb !important;
		.MuiInputBase-root.MuiFilledInput-root,
		.MuiInputBase-input.MuiFilledInput-input {
			border-radius: 4px 4px 0 0 !important;
			background: #ebebeb !important;
		}
		.MuiInputBase-input.MuiFilledInput-input {
			border-bottom: solid 1px #979797;
		}
		.MuiInputBase-root {
			background: #ebebeb !important;
			box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
		}
		.MuiFormLabel-root.Mui-focused {
			color: var(--palette-primary-main);
		}
		.MuiIconButton-root:hover {
			color: var(--palette-primary-main) !important;
		}
		.MuiFormHelperText-root.Mui-error {
			background-color: #fff !important;
		}
		.MuiInputBase-input,
		.MuiInputLabel-root {
			color: #000;
		}
	}

	fieldset.MuiFormControl-root {
		border-radius: 4px 4px 0 0 !important;
	}
	.MuiSelect-icon {
		width: 24px;
		height: 24px;
		color: #000;
		right: 10px;
	}

	.select-with-border {
		.MuiFormControl-root {
			background-color: #fff !important;
			color: #000 !important;
		}
		.MuiSelect-root.MuiInput-input {
			border: 1px #979797 solid;
			background-color: #fff !important;
			border-radius: 4px !important;
			padding-right: 39px;
			&:focus {
				border-radius: 4px !important;
			}
		}
		.MuiInput-underline {
			&:before,
			.Mui-focused:after,
			&:after {
				display: none !important;
			}
		}

		.MuiSelect-icon {
			width: 24px;
			height: 24px;
			color: #000;
			right: 10px;
		}
		.MuiInput-formControl {
			margin-top: 0 !important;
			color: #000 !important;
			border-radius: 4px !important;
			background: #fff !important;
		}
		.MuiInputLabel-formControl {
			margin-top: -15px !important;
			transform: opacity(0);
			&.Mui-focused {
				transform: translate(0, 24px) scale(1) !important;
				color: #000 !important;
			}
			&.MuiFormLabel-filled {
				display: none;
			}
		}
	}
	.select-with-label-outside {
		.select-with-border {
			.MuiInputLabel-formControl.Mui-focused {
				display: block !important;
				color: var(--palette-primary-main) !important;
				margin-top: -47px !important;
				margin-left: 0;
				font-size: 14px !important;
				font-weight: 400;
				line-height: 1.43;
				letter-spacing: 0.5px;
				font-family: Source Sans Pro, sans-serif;
			}
		}
	}
	.select-centered {
		.MuiSelect-icon {
			top: 0 !important;
		}
	}
	.cohort-dashed-border {
		margin: 16px 4px;
		width: calc(100% - 8px);
		padding: 16px;
		// Custom dashed border generator https://kovart.github.io/dashed-border-generator/
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23BEBEBEFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
		border-radius: 5px;
	}
	.input-h4 {
		.MuiFormControl-root .MuiInputBase-input {
			font-size: 20px !important;
			font-weight: 600;
			letter-spacing: 0.25px;
		}
	}
	.cohort-question-edit-box {
		margin: 16px 0;
		width: calc(100% - 8px);
		border-radius: 4px;
		padding: 16px;
		// Custom dashed border generator https://kovart.github.io/dashed-border-generator/
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23BEBEBEFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	}
	.cohort-question-box {
		border-radius: 6px;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
		border-left: 10px #d9e6fc solid;
	}
	.buttons-box-graph {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: flex-end;
		margin: 10px 0 -5px;
		button {
			padding: 4px 24px !important;
			min-width: 92px;
		}
	}
	.MuiTypography-h4 {
		font-size: 18px;
		letter-spacing: 0.31px !important;
		font-weight: bold;
	}
}
