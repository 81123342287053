.rdsu-image img {
    max-width: 100%;
    max-height: 100%;
}

.react-dropzone-s3-uploader {
    z-index: 11;
}

.fileUploaderWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
}

.rdsu-filename {
    word-break: break-all;
}
.dropzone-file {
    margin-top: 32px;
}
.file-question-wrapper {
    padding: 32px 16px;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        padding: 16px 8px;
        font-size: 12px;
        .MuiButton-root {
            font-size: 12px;
            height: auto;
            padding: 5px;
        }
    }
}
