.uitype-1 {
	.MuiFormHelperText-contained {
		background-color: #fff !important;
	}
	.select-selector .MuiFormControl-root {
		background-color: #E6E6E6 !important;
		color: #000 !important;
		.MuiSelect-icon {
			color: #2d2d2d !important;
		}
		&.Mui-error {
			color: #f1493e !important;
		}
	}
	.MuiFormControl-root {
		.MuiInputLabel-root {
			color: #000 !important;

			&.Mui-disabled {
				opacity: 0.4;
			}
			&.Mui-error {
				color: #f1493e !important;
			}
		}

		.MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorSecondary.MuiFilledInput-colorSecondary {
			background-color: #E6E6E6 !important;
			color: #000 !important;

			&.Mui-disabled {
				background: rgba(0,0,0,0.12) !important;
			}

			.MuiIconButton-root {
				color: #a4a4a4 !important;
			}

		}
		.MuiSelect-filled.MuiSelect-filled {
			color: #000 !important;
			background-color: #E6E6E6 !important;
		}
	}

	.MuiFormControlLabel-root,
	.MuiFormLabel-root.MuiFormLabel-colorSecondary.Mui-focused.MuiInputLabel-filled,
	.MuiFormLabel-colorSecondary.Mui-focused {
		color: #000 !important;
	}

	.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary:not(.Mui-checked) svg {
		border-color: #0067d8 !important;
	}

	.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary.Mui-checked svg {
		fill: #0067d8 !important;
	}

	.MuiCheckbox-colorSecondary.Mui-checked:hover {
		background-color: transparent !important;
	}


}
