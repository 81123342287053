@import "../../../shared-styles/index";

// Background-images should be written individually for all projects, because
// Relative SCSS images imports outside of src/ are not supported by SASS
.error-page {
    background-image: url("../assets/shared/error/arrows-light.png");
}

.splash-screen {
    background-image: url("../assets/shared/splash-screen/arrows-bright-compressed.jpg");
    &__logo {
        background-image: url("../assets/shared/logo-wordmark-white.svg");
    }
}
