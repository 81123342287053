.infoAlert {
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 1px 1px 0 var(--palette-primary-main);
    background-color: var(--palette-primary-A300);
    display: flex;
    color: var(--palette-secondary-dark);
    align-items: center;
    width: 100% !important;
    margin: 30px 0 !important;
    @media only screen and (max-device-width: 640px) {
        flex-wrap: wrap;
    }
    &.infoAlertInPaper {
        width: calc(100% - 122px) !important;
        margin: 65px auto 0 !important;
        @media only screen and (max-device-width: 640px) {
            width: calc(100% - 64px) !important;
            margin: 37px auto 0 !important;
        }
    }
    svg {
        font-size: 30px;
        margin-right: 25px;
        color: var(--palette-primary-main);
        @media only screen and (max-device-width: 640px) {
            margin-bottom: 10px;
        }
    }
}