// Bilateral
.bilateral-table {
	border-left: 2px var(--palette-primary-A100) solid;
	border-right: 2px var(--palette-primary-A100) solid;

	@media only screen and (max-width: 767px) {

		.MuiGrid-grid-xs-6 {
			padding-bottom: 10px !important;
		}
	}


}
.bilateral-table-head-wrap {
	@media only screen and (max-width: 768px) {
		border-bottom: 2px solid #ededf1;
	}		
}
.admin .bilateral-table~div:last-of-type {
	padding-bottom: 10px;
}

.bilateral-container {
	border-left: 2px var(--palette-primary-A100) solid;
	border-right: 2px var(--palette-primary-A100) solid;

	.bilateral-container-title {
		padding: 10px;

	}

	.MuiGrid-grid-xs-8 {
		display: flex;
	}

	// Bilateral grouped

	.bilateral-container-content.bilateral-grouped {
		.bilateral-grid.MuiGrid-grid-xs-6:first-child {
			max-width: 66.666667%;
			flex-basis: 66.666667%;

			.errorWrapper {
				margin-left: calc(50% + 21px);

				&::before {
					left: calc(50% + 10px);
				}

			}

			.button-remove-answer-wrapper {
				margin-left: auto;
			}

		}

		.bilateral-grid.MuiGrid-grid-xs-6:last-child {
			max-width: 33.333333%;
			flex-basis: 33.333333%;

			@media screen and (max-width: 767px) {
				border-left: none !important;
			}
		}

		.container-questions-range-header,
		.container-questions-range {
			>.MuiGrid-grid-xs-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-6 {
				max-width: 100%;
				flex-basis: 100%;
				padding-right: 0;
			}

			>.MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6 {

				+.MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6,
				+.MuiGrid-grid-xs-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-6 {
					max-width: 50%;
					flex-basis: 50%;
					padding-left: 12px;
				}
			}

			.MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6 {
				max-width: 50%;
				flex-basis: 50%;
			}
		}
	}
}

.bilateral-grid {
	.questionWrapper {
		border: none;
		margin: 0;
	}

	.MuiGrid-root.MuiGrid-grid-xs-6:empty {
		display: none;

		+.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 {
			max-width: 100%;
			flex-basis: 100%;

			>div {
				width: auto;
			}

			.radio-group-header-text {
				width: calc(50% - 8px);
				margin-left: auto;
			}

			.container-questions-range-header,
			.container-questions-range {
				padding-left: 50%;
			}
		}
	}

	.select-component,
	.dropdown-component {
		min-width: initial !important;
		margin-left: 0 !important;
	}

	.range-component {
		.MuiGrid-grid-sm-4 {
			max-width: 100%;
			flex-basis: 100%;
		}

		.MuiGrid-grid-sm-2 {
			max-width: 50%;
			flex-basis: 50%;
			display: flex;
			padding-left: 22px;

			>div {
				width: 100%;
			}
		}

		.range-component-label {
			max-width: 50%;
			flex-basis: 50%;
		}

		.MuiFormControl-fullWidth {
			width: 100%;
			min-height: initial !important;
		}
	}

	.container-questions-range-header {
		padding-bottom: 0;
	}

	.container-questions-range {
		padding-bottom: 0;
		padding-top: 10px;

		.MuiTypography-subtitle2 {
			margin-bottom: 0;
		}
	}

	.MuiTypography-subtitle1,
	.MuiTypography-subtitle2,
	.MuiTypography-body2,
	.MuiTypography-body1,
	.MuiFormControlLabel-root {
		font-size: 14px !important;
		margin-right: 0;
		word-break: break-word;
	}

	.MuiGrid-grid-xs-3 {
		word-break: break-all;
		max-width: 50%;
		flex-basis: 50%;
		padding-left: 12px;

	}

	.container-radiogroup-grid>.MuiFormGroup-root>.MuiFormGroup-root>.MuiFormControlLabel-root,
	.container-radiogroup-grid>.MuiFormGroup-root>.MuiFormGroup-root>.MuiGrid-root {
		border: none;
		padding: 0 !important;
	}

	.questionWrapper {

		>div,
		.MuiFormControl-root {
			margin-top: 0 !important;
		}

		@media screen and (max-width: 767px) {
			padding-right: 4px;
		}
	}

	.dropzone-file {
		margin-top: 0;

		.MuiButton-containedSizeLarge {
			padding: 10px;
		}
	}

	.global-input-wrapper {
		min-height: unset;
	}


	.radiogroup-item {
		margin-bottom: 5px !important;

		.MuiGrid-grid-sm-6:last-child {
			padding-left: 12px;
		}
	}

	.container-questions-grid {
		flex-direction: column;
	}

	.MuiGrid-container {
		.MuiGrid-grid-sm-6:first-child {
			padding-right: 10px;
		}

		.MuiGrid-grid-sm-6:last-child {
			padding-left: 10px;
		}
	}
	.bilateral-radiogroup-header-text.MuiGrid-container {
		.MuiGrid-grid-xs-6:first-child,
		.MuiGrid-grid-sm-6:first-child {
			padding-right: 10px;
			max-width: 50%;
			flex-basis: 50%;
		}
		.MuiGrid-grid-xs-6:last-child
		.MuiGrid-grid-sm-6:last-child {
			padding-left: 10px;
			max-width: 50%;
			flex-basis: 50%;

		}

		.MuiGrid-item {
			justify-content: center;
		}
	}

}

@media screen and (max-width: 767px) {
	.bilateral-container {

		.bilateral-container-title,
		.bilateral-container-content-outer {
			max-width: 100% !important;
			flex-basis: 100% !important;
		}

		.bilateral-container-content-outer {
			.MuiGrid-grid-xs-6 {
				border-left: none !important;
			}
		}
	}


}

.bilateral-survey {
	margin-bottom: 0 !important;
	border-top: 2px var(--palette-primary-A100) solid;
	border-bottom: 2px var(--palette-primary-A100) solid;

	+.bilateral-survey {
		border-top: none;
	}

	+.standart-survey .questionWrapper {
		border-top: none;
	}

	.question-container-children {
		>div:last-child:not(:empty) {
			>div {
				margin-bottom: 0 !important;
				padding-bottom: 0 !important;
			}
		}

		>div:last-child:empty {
			margin-bottom: -10px !important;
		}
	}

	.MuiGrid-spacing-xs-2 {
		@media only screen and (max-width: 767px) {
			margin: 0 !important;
		}
	}

	.MuiGrid-spacing-xs-2>.MuiGrid-item {
		padding: 0 8px 8px 8px !important;

		@media only screen and (max-width: 767px) {
			padding: 0 !important;
		}

	}

	.MuiSlider-markLabel {
		@media only screen and (max-width: 767px) {
			font-size: 8px !important;
		}
	}

	.MuiButton-label {
		@media only screen and (max-width: 767px) {
			white-space: normal;
		}

	}

	.MuiButton-root {
		@media only screen and (max-width: 767px) {
			min-width: unset;
		}
	}

	.button-remove-answer.MuiButton-root {
		@media only screen and (max-width: 767px) {
			letter-spacing: 0;
			font-size: 10px;
			padding: 0 5px !important;
			margin: 10px 0 10px auto !important;
		}
	}

}

.lazyload-wrapper+.lazyload-wrapper {

	.bilateral-survey {
		border-top: none;
	}

	.standart-survey .questionWrapper {
		border-top: none;
	}
}

.standart-survey+.standart-survey,
.standart-survey+.bilateral-survey {
	margin-top: -2px !important;
}

.bilateral-survey {
	.button-remove-answer-wrapper {
		width: calc(50% - 12px);
		flex-basis: calc(50% - 12px);

		.button-remove-answer {
			margin: 10px 0 !important;
			padding: 3px 12px !important;
			height: auto !important;
			letter-spacing: 0.5px !important;
		}
	}
}
