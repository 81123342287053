.selectStyle {
    .MuiSelect-selectMenu {
        padding: 0 12px 5px 0 !important;
        min-height: 48px;
        display: flex;
        align-items: center;
    }
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    padding-left: 14px;
}

#filled-select-currency-label {
    transform: translate(12px, 10px) scale(0.75) !important;
}

.admin-select-outlined {
    .MuiSelect-root.MuiSelect-select {
        border: 1px #979797 solid;
        background-color: #fff !important;
        border-radius: 4px !important;
        padding: 8px 39px 8px 12px;
        font-size: 16px;
        &:focus {
            border-radius: 4px !important;
        }
    }
    .MuiSelect-icon {
        width: 24px;
        height: 24px;
    }
    // Select with vertical line
    &.admin-select-outlined-line {
        .MuiInput-underline:after,
        .MuiInput-underline:before {
            display: none !important;
        }
        .MuiSelect-icon {
            border-left: 1px #979797 solid;
            padding: 0 5px;
            width: 34px;
            height: 24px;
        }
        .MuiSelect-root.MuiSelect-select {
            padding: 8px 49px 8px 12px;
        }
    }
}
