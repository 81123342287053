.mainHeaderMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    align-items: center;
}

.mainHeaderMenuMobile {
    position: relative;
    z-index: 1001;
    &.navOpened {
        position: fixed;
        top: 0;
        right: 0;
        display: block;
        min-width: 220px;
        max-width: 220px;
        min-height: 100vh;
        max-height: 100vh;
        background-color: var(--palette-secondary-dark);
        z-index: 1;
        transition: all 0.2s ease;
        overflow: hidden;
        *:focus {
            outline: 2px #fff solid !important;
        }
        .mainHeaderMenuMobile-menuWrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
        }
        .mainHeaderMenuMobile-buttonLink {
            color: #fff !important;
            text-align: left;
            margin-bottom: 10px;
            &:hover,
            &:focus {
                color: #fff !important;
                svg {
                    color: #fff !important;
                }
            }
        }
        .mainHeaderMenuMobile-buttonIcon {
            padding: 10px;
            color: #fff !important;
            svg {
                color: #fff !important;
            }
            &:hover,
            &:focus {
                color: #fff !important;
                svg {
                    color: #fff !important;
                }
            }
        }
        .mainHeaderMenuMobile-separator {
            width: 100%;
            height: 1px;
            margin: 10px 0;
            opacity: 0.2;
        }
    }
    &.navClosed {
        position: fixed;
        top: 0;
        right: 0;
        background-color: var(--palette-secondary-dark);
        min-height: 100vh;
        max-height: 100vh;
        min-width: 0;
        max-width: 0;
        width: 0;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
    }
    >div {
        min-width: 220px;
    }
}