
.admin-import {
	padding-bottom: 70px !important;
    height: auto !important;
	&.admin-fullheight-wrapper .paper-inner {
		overflow: visible !important;
		.admin-import-table {
			width: calc(100% + 64px);
			margin: 0 -32px;
			border-top: 1px solid #e0e0e0;
		}
	}
}
.admin-table-fullheight.admin-import-table {
	height: 100% !important;
	min-height: 100% !important;
	overflow: auto;
}
