.bodyImitateModal {
    .menuButton {
        display: none !important;
    }
}

@media only screen and (max-device-width: 959px) {
    
    .imitateModalOnMobileClosed {
        display: none;
    }
}
@media only screen and (max-width: 959px) {
    .imitateModalOnMobileClosed {
        display: none;
    }
}
@media only screen and (max-device-width: 959px) and (-webkit-min-device-pixel-ratio: 2) {
    .imitateModalOnMobileClosed {
        display: none;
    }
}

.bodyImitateModal {
    .imitateModalOnMobileClosed,
    .imitateModalOnMobileOpened {
        display: block;
        padding-top: 28px;
        @media only screen and (max-width: 959px) {
            display: block;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--palette-light);
            overflow: auto;
            padding: 50px 16px !important;
        }
        @media only screen and (max-device-width: 959px) {
            display: block;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--palette-light);
            overflow: auto;
            padding: 50px 16px !important;
        }
        @media only screen and (max-device-width: 959px) and (-webkit-min-device-pixel-ratio: 2) {
            display: block;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--palette-light);
            overflow: auto;
            padding: 50px 16px !important;
        }
        #closeButtonWrapper {
            position: fixed;
            top: 0;
            left: 0;
            justify-content: flex-end;
            background: var(--palette-light);
            width: 100%;
            z-index: 4;
        }
        #closeButton {
            background: var(--palette-light);
            display: block;
            border: none;
            padding: 6px;
            .MuiSvgIcon-root {
                font-size: 24px;
            }
        }
    }
}

@media only screen and (min-device-width: 959px) {
    .imitateModalOnMobileOpened,
    .imitateModalOnMobileClosed {
        display: block !important;
    }
}
@media only screen and (min-width: 959px) {
    .imitateModalOnMobileOpened,
    .imitateModalOnMobileClosed {
        display: block !important;
    }
}
@media only screen and (min-width: 959px) and (-webkit-min-device-pixel-ratio: 2) {
    .imitateModalOnMobileOpened,
    .imitateModalOnMobileClosed {
        display: block !important;
    }
}

@media only screen and (min-width: 640px) and (min-width: 959px)  {
.observational-protocol-menu-details {
    display: grid;
    grid-template-columns: 50% 50%;
}
}
