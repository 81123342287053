@media print {
	[data-print="none"] {
		display: none !important;
	}
	html {
		margin: 0;
		padding: 0;
		box-shadow: 0;
		background: transparent !important;
		height: auto;
		min-height: auto;
		display: block;
		page-break-after: avoid;
		page-break-before: avoid;
		overflow: auto !important;
	}

	body {
		margin: 0 !important;
		padding: 0 !important;
		background: transparent !important;
		height: unset !important;
		min-height: unset !important;
		display: block;
		page-break-after: avoid;
		page-break-before: avoid;
		overflow: visible !important;
		min-width: 767px;
	}
	.no-print,
	.left-navigation,
	.MuiPopover-root {
		display: none !important;
	}
	.mainBlock {
		min-height: unset !important;
		max-height: unset !important;
		overflow-x: initial !important;
		flex-grow: unset !important;
		background: transparent !important;
	}
	.main-content {
		overflow-x: initial !important;
		min-width: unset !important;
		background: transparent !important;
		display: block !important;
		min-height: unset !important;
		max-height: unset !important;
		flex-grow: unset !important;
	}

	button:not(.MuiIconButton-root):not(.button-icon) {
		display: none;
	}

	.case-alert-table-wrapper,
	.task-info-inner-table,
	.alert-table-inner {
		max-height: unset !important;
	}

	.detail-header {
		background: transparent !important;
		border: 1px #bbb solid;
		*:not(.case-phi *):not(.MuiIconButton-label):not(svg):not(path) {
			background: transparent !important;
		}
		* {
			color: #000 !important;
		}
	}
	.page-wrapper {
		padding-top: 0 !important;
	}
	.composite-label-paper-content-wrapper,
	.composite-label-paper-content,
	.component-label-paper-content-wrapper,
	.component-label-paper-content {
		overflow: visible !important;
		height: auto !important;
	}
	.component-label-paper-content {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.label-paper-inner {
		margin-left: 0 !important;
		margin-right: 0 !important;
		width: 100% !important;
	}
	.component-label-paper {
		margin-top: 20px !important;
		margin-bottom: 20px !important;
		transform: none !important;
	}
	.component-label-paper,
	.component-label-paper-title,
	.component-label-small,
	.component-label-k-number,
	.component-label-number,
	.observational-protocol-menu-inner {
		page-break-inside: avoid;
		break-inside: avoid;
		page-break-after: avoid;
		break-after: avoid;
		position: relative;
		min-height: 50px;
	}
	.fileUploaderWrapper,
	.dropzone-file {
		display: block !important;
		page-break-inside: avoid;
		break-inside: avoid;
		page-break-after: avoid;
		break-after: avoid;
		position: relative;
		text-align: center;
	}
	.wide-container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		display: block !important;
	}
	div[class*="MuiPaper-elevation"] {
		box-shadow: none !important;
		border: none !important;
		min-height: 0 !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-12,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-12,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-12,
	.MuiGrid-grid-sm-12,
	.MuiGrid-grid-md-12,
	.MuiGrid-grid-lg-12 {
		flex-grow: 0 !important;
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-11,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-11,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-11,
	.MuiGrid-grid-sm-11,
	.MuiGrid-grid-md-11,
	.MuiGrid-grid-lg-11 {
		flex-grow: 0 !important;
		max-width: 91.666667% !important;
		flex-basis: 91.666667% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-10,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-10,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-10,
	.MuiGrid-grid-sm-10,
	.MuiGrid-grid-md-10,
	.MuiGrid-grid-lg-10 {
		flex-grow: 0 !important;
		max-width: 83.333333% !important;
		flex-basis: 83.333333% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-9,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-9,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-9,
	.MuiGrid-grid-sm-9,
	.MuiGrid-grid-md-9,
	.MuiGrid-grid-lg-9 {
		flex-grow: 0 !important;
		max-width: 75% !important;
		flex-basis: 75% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-8,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-8,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-8,
	.MuiGrid-grid-sm-8,
	.MuiGrid-grid-md-8,
	.MuiGrid-grid-lg-8 {
		flex-grow: 0 !important;
		max-width: 66.666667% !important;
		flex-basis: 66.666667% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-7,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-7,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-7,
	.MuiGrid-grid-sm-7,
	.MuiGrid-grid-md-7,
	.MuiGrid-grid-lg-7 {
		flex-grow: 0 !important;
		max-width: 58.333333% !important;
		flex-basis: 58.333333% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-6,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-6,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-6,
	.MuiGrid-grid-sm-6,
	.MuiGrid-grid-md-6,
	.MuiGrid-grid-lg-6 {
		flex-grow: 0 !important;
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-5,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-5,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-5,
	.MuiGrid-grid-sm-5,
	.MuiGrid-grid-md-5,
	.MuiGrid-grid-lg-5 {
		flex-grow: 0 !important;
		max-width: 41.666667% !important;
		flex-basis: 41.666667% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-4,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-4,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-4,
	.MuiGrid-grid-sm-4,
	.MuiGrid-grid-md-4,
	.MuiGrid-grid-lg-4 {
		flex-grow: 0 !important;
		max-width: 33.333333% !important;
		flex-basis: 33.333333% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-3,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-3,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-3,
	.MuiGrid-grid-sm-3,
	.MuiGrid-grid-md-3,
	.MuiGrid-grid-lg-3 {
		flex-grow: 0 !important;
		max-width: 25% !important;
		flex-basis: 25% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-2,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-2,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-2,
	.MuiGrid-grid-sm-2,
	.MuiGrid-grid-md-2,
	.MuiGrid-grid-lg-2 {
		flex-grow: 0 !important;
		max-width: 16.666667% !important;
		flex-basis: 16.666667% !important;
	}
	.MuiGrid-grid-xs-12 .MuiGrid-grid-sm-1,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-md-1,
	.MuiGrid-grid-xs-12 .MuiGrid-grid-lg-1,
	.MuiGrid-grid-sm-1,
	.MuiGrid-grid-md-1,
	.MuiGrid-grid-lg-1 {
		flex-grow: 0 !important;
		max-width: 8.333333% !important;
		flex-basis: 8.333333% !important;
	}
	.page-case-detail h2 {
		font-size: 24px;
	}
	.header-case-detail {
		flex-wrap: nowrap;
	}
	.surveyDescription {
		padding-top: 20px;
	}
	.component-label-paper-content-wrapper,
	.component-label-paper-content {
		border: 1px transparent solid;
	}
	.wide-container,
	.print-wrapper {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.print-wrapper {
		padding-top: 0 !important;
	}
	.page-topbar {
		display: none !important;
	}
	table {
		th {
			padding: 16px 0;
			.MuiTypography-caption {
				min-width: 100px;
			}
		}
		td {
			vertical-align: middle;
			min-width: unset !important;
			a {
				display: inline-block !important;
			}
			.MuiGrid-item {
				display: inline-flex !important;
				margin: auto 0 !important;
			}
			&.MuiTableCell-sizeSmall {
				padding: 6px;
			}
		}
	}
	.print-block-visible {
		max-height: unset !important;
		overflow: visible !important;
	}
	.report-container {
		display: flex !important;
		flex-direction: column-reverse;
	}
	.report-container-navigator {
		display: block !important;
	}
	.report-container-report {
		display: block !important;
		flex-grow: 0 !important;
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.infoAlert.infoAlertInPaper {
		page-break-inside: avoid;
		break-inside: avoid;
	}
	.MuiDialog-root {
		&:after {
			content: "";
			background: #fff;
			width: 100%;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			overflow: hidden;
			z-index: -1;
		}
		.MuiBox-root,
		.MuiGrid-container {
			*:not(.flag):not([class^="MuiSwitch"]) {
				background: #fff !important;
				color: #000 !important;
				box-shadow: none !important;
				-webkit-text-fill-color: #000 !important;
			}
			.react-tel-input {
				border-bottom: 1px #011020 solid !important;
			}
			svg {
				fill: #000 !important;
			}
		}
		.MuiBox-root,
		.MuiGrid-container {
			.MuiTouchRipple-root {
				display: none !important;
			}
			*[class^="MuiSwitch"] {
				&.MuiSwitch-track {
					background: #fff !important;
					border: 1px #aaa solid;
					opacity: 1;
				}

				&.MuiSwitch-thumb {
					border: 1px #aaa solid;
					background: #fff !important;
				}
				.MuiSwitch-switchBase.Mui-checked {
					& + .MuiSwitch-track {
						background: #ddd !important;
					}
					.MuiSwitch-thumb {
						background: #222 !important;
						border: 1px #222 solid;
					}
				}

				&.MuiSwitch-input,
				&.MuiSwitch-colorPrimary.Mui-checked {
					color: #000 !important;
				}

				&.MuiButtonBase-root.Mui-checked .MuiSwitch-thumb {
					background: #000;
				}
				&.MuiSwitch-switchBase {
					background: transparent !important;
				}
			}
		}
		.MuiDialogContent-root {
			> form > .MuiGrid-container,
			> .MuiGrid-container {
				background: #fff !important;
			}
		}
		.MuiSwitch-switchBase {
			padding: 0 !important;
			margin: 9px !important;
		}
		.MuiInputLabel-filled {
			transform: translate(0, 20px) scale(1);
		}
		.MuiInputLabel-filled.MuiInputLabel-shrink {
			transform: translate(0, 10px) scale(0.75);
		}
		.MuiFilledInput-input {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
	body.modal-open {
		#root {
			display: none !important;
		}
		position: relative;
		&:after {
			content: "";
			background: #fff;
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			overflow: hidden;
		}
		.MuiBackdrop-root {
			display: none !important;
		}
	}
	.dashboard-background {
		background: #fff !important;
	}
	.personal-plan-title {
		color: #000 !important;
	}
	.case-phi-wrapper {
		align-self: stretch;
		display: flex;
		align-items: center;
	}
	.case-phi {
		height: 100%;
	}

	.case-phi:not(.MuiIconButton-label):not(svg):not(path) {
		background-color: #eee !important;
		* {
			background-color: #eee !important;
		}
	}
	.container-questions-range .MuiFormGroup-root {
		flex-wrap: nowrap;
	}
	.task-header-section-phi {
		flex-grow: 0 !important;
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
	.task-header-section-header {
		flex-grow: 0 !important;

		max-width: 50% !important;
		flex-basis: 50% !important;
		align-self: stretch;
		display: flex;
		align-items: center;
	}
	.header-case-detail .MuiGrid-item,
	.header-task-detail .MuiGrid-item {
		padding: 8px !important;
	}
	.case-header-section-inner {
		align-self: stretch;
		display: flex;
		align-items: center;
	}
	.task-phi-button-show-wrapper button {
		padding: 0;
	}
	.task-phi-name {
		font-size: 23px !important;
		font-weight: bold !important;
	}
	.MuiDialog-root .MuiBox-root .selector-chip-wrapper,
	.MuiDialog-root .MuiGrid-container .selector-chip-wrapper,
	.selector-chip-wrapper {
		border: 1px #ccc solid !important;
		padding: 0 !important;
		margin: 2px 4px !important;
		border-radius: 20px;
		svg.MuiChip-deleteIcon {
			fill: #ccc !important;
		}
	}
	.status-cell {
		margin-right: 8px;
	}
	//
	//
	// Print for patient portal
	//
	//
	.mainPatientWrapper {
		min-height: unset !important;
		max-height: unset !important;
		overflow-x: initial !important;
		flex-grow: unset !important;
		background: transparent !important;
		display: block !important;
		padding: 0 !important;
	}
	.mainHeaderMenu {
		display: none;
	}
	.survey-index {
		background: transparent !important;
		min-height: unset !important;
	}
	.survey-form-page {
		margin: 0 auto !important;
		padding: 0 !important;
	}
	.survey-form-wrapper {
		flex-grow: 0 !important;
		max-width: 100% !important;
		flex-basis: 100% !important;
		margin-top: 60px;
	}
	.mainHeaderOuter,
	.mainHeaderInner {
		position: relative !important;
	}
	.surveyDescription {
		background: transparent !important;
		color: #000 !important;
		border-left: 8px #11121c solid;
	}
	.dashboard-wrapper {
		flex-direction: row !important;
	}
	.patient-updates-item {
		flex-wrap: nowrap !important;
	}
	#root {
		min-height: unset !important;
	}
	.patient-main-content-inner {
		height: unset !important;
	}
	.patient-images-main,
	.rootChart {
		background: transparent !important;
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
		* {
			color: #000 !important;
		}
		.boxDueLabel *, 
		.boxScoreLabel *, 
		.survey-response-results-new-score {
			color: #fff !important;
		}
	}
	.patient-case-details-wrapper {
		.patient-treatment-img-wrapper,
		.patient-treatment-desc-wrapper,
		.patient-pathology-img-wrapper,
		.patient-pathology-desc-wrapper {
			flex-grow: 0 !important;
			max-width: unset !important;
			flex-basis: unset !important;
		}
		.patient-treatment-img-wrapper {
			order: 1;
			max-width: 700px !important;
			max-height: 1200px;
			margin: auto;
			page-break-inside: avoid;
			break-inside: avoid;
		}
		.patient-treatment-desc-wrapper {
			order: 2;
		}
		.patient-pathology-desc-wrapper {
			order: 4;
		}
		.patient-pathology-img-wrapper {
			order: 3;
			max-width: 700px !important;
			max-height: 1200px;
			margin: auto;
			page-break-inside: avoid;
			break-inside: avoid;
		}

	}
	
	.image-item-wrapper,
	.rootChart,
	.chartGraph,
	.patient-bar-chart,
	.patient-bar-chart svg,
	.patient-bar-chart svg g {
		display: block !important;
		position: relative;
		page-break-inside: avoid;
		break-inside: avoid;
	}
	.full-albom-wrapper,
	.patient-images-root {
		display: block !important;
	}
	
	.chartGraph {
		overflow: visible !important;
	}
	.patient-bar-chart {
		padding-left: 20px !important;
	}
	.question-container-title span:first-child {
		display: inline-block !important;
		position: relative;
		page-break-inside: avoid;
		break-inside: avoid;
	}
	.question-container-title span:last-child {
		display: inline !important;
	}
	.questionWrapper {
		display: inline-block !important;
		position: relative;
		page-break-inside: avoid;
		break-inside: avoid;
	}
}
