.questionWrapper {
	.MuiAutocomplete-tag {
		border-radius: 4px !important;
		max-width: 100% !important;
		align-items: flex-start !important;
	}

	.MuiAutocomplete-root {
		border-radius: 4px;
		background: #fff;
	}
	.MuiAutocomplete-inputRoot {
		padding: 0 0 0 8px!important;
	}
	.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
		color: var(--palette-secondary-main);	
		min-height: 22px;
		padding: 8px 0 !important;
		font-size: 14px !important;
		&.Mui-disabled {
			color: rgba(0, 0, 0, 0.38);
		}
	}
	.MuiInputBase-input::-webkit-input-placeholder {
		opacity: 1 !important;
	}
	.MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
		width: 24px;
		height: 24px;
		font-size: 38px;
		fill: var(--palette-secondary-main);
	}

	.MuiChip-root {
		padding-top: 4px !important;
		padding-bottom: 4px !important;
		height: auto !important;
		border-radius: 3px !important;
		font-size: 16px !important;
		margin: 4px !important;
		.MuiChip-label {
			overflow: initial;
			white-space: normal;
			text-overflow: initial;
			line-height: 1.1;
			padding: 1px 5px 0;
			color: var(--palette-secondary-main) !important;
			word-break: break-word;
		}

		.MuiChip-deleteIcon {
			margin-left: 0;
		}
	}

	.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
		min-width: 100px;
	}

	.MuiAutocomplete-inputRoot .MuiChip-root + .MuiAutocomplete-input {
		min-width: unset;
		width: 60px;
		opacity: 0;
	}

	.MuiAutocomplete-endAdornment {
		right: 0 !important;
		top: auto !important;
		bottom: 5px !important;

		.MuiIconButton-root {
			width: 24px;
			height: 24px;
			margin: 3px;
			padding: 3px;
		}
	}
}
.MuiAutocomplete-popper {
	padding: 0 !important;
	margin: 0 !important;
	ul.MuiAutocomplete-listbox {
		padding: 0 !important;
		margin: 0 !important;
		
		li {
			font-size: 15px !important;
			padding: 4px 16px !important;
			min-height: 38px;
			background: #eeeeee;
			&:hover,
			&:focus {
				background: #e3e3e3;
			}
			@media only screen and (max-device-width: 480px) {
				display: flex;
				align-items: center;
				word-break: break-all;
			}
			.MuiIconButton-label input[type="checkbox"] {
				width: 18px !important;
				height: 18px !important;
				min-width: 18px !important;
				min-height: 18px !important;
				max-width: 18px !important;
				max-height: 18px !important;
			}
			.MuiIconButton-root {
				margin: -3px 6px -1px 0 !important;
				padding: 0 !important;
				width: 18px !important;
				height: 18px !important;
				min-width: 18px !important;
				min-height: 18px !important;
				max-width: 18px !important;
				max-height: 18px !important;
				background: transparent !important;

				&:hover,
				&:focus {
					background: transparent !important;
				}

				svg.MuiSvgIcon-root {
					margin: 0 !important;
					padding: 0 !important;
					transform: none !important;
					width: 18px !important;
					height: 18px !important;
					min-width: 18px !important;
					min-height: 18px !important;
					max-width: 18px !important;
					max-height: 18px !important;
				}
				&.Mui-checked {
					svg.MuiSvgIcon-root {
						margin: -2px 0 0 0 !important;
						padding: 0 !important;
						transform: none !important;
						width: 22px !important;
						height: 22px !important;
						min-width: 22px !important;
						min-height: 22px !important;
						max-width: 22px !important;
						max-height: 22px !important;
					}					
				}
			}
		}
	}

}
