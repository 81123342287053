.MuiButton-text {
    // .MuiTouchRipple-root {
    //     display: none;
    // }
    .MuiButton-label {
        position: relative;
        display: flex;
        &:after {
            position: absolute;
            content: "";
            display: block;
            margin: auto;
            bottom: 0;
            left: 50%;
            height: 1px;
            width: 0;
            background: transparent;
            transition: width 0.25s ease, background-color 0.25s ease, left 0.25s ease;
        }
    }
    &.Mui-focusVisible {
        .MuiButton-label:after {
            width: 100%;
            left: 0;
            background: var(--palette-primary-main);
        }
    }
    &.MuiButton-textPrimary {
        .MuiButton-label {
            color: var(--palette-primary-A500);
        }
        svg {
            fill: var(--palette-primary-A500);
            height: 20px;
            width: 20px;
            margin: 5px;
        }
    }
    &.MuiButton-textSizeSmall {
        margin: 5px;
        height: 32px;
    }
}
.MuiButton-textSizeLarge.MuiButton-text.MuiButton-textPrimary {
    padding: 6px 11px !important;
    height: 36px;
    display: inline-flex !important;
    align-items: center !important;
    svg {
        margin-right: 0 !important;
        margin-bottom: 2px !important;
        margin-top: 4px !important;
    }
    &:focus .MuiTouchRipple-root > span > span {
        background-color: var(--palette-primary-main);
    }
}
.button-icon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none !important;
    width: 34px !important;
    height: 34px !important;
    border-radius: 3px !important;
    cursor: pointer !important;
    transition: all 0.25s ease !important;
    &:hover {
        background-color: rgba(229, 229, 229, 0.5) !important;
        text-decoration: none !important;
    }
    &.Mui-focusVisible .MuiButton-label:after {
        display: none !important;
    }
    .button-icon-svg {
        display: flex !important;
        font-size: 20px;
        width: 20px;
        height: 20px;
        color: var(--palette-primary-main);
    }
}
.MuiIconButton-root.Mui-disabled {
    svg {
        fill:  var(--palette-text-disabled) !important;
    }
}
