/* REM override to 10px */

html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: Source Sans Pro, sans-serif !important;
}

* {
    box-sizing: border-box;
}

a:not(.MuiButton-textSecondary):not(.MuiButton-containedPrimary):not(.MuiTypography-colorTextSecondary):not(.MuiTypography-colorSecondary):not([style*="color: rgb(255, 255, 255)"]) {
    color: var(--palette-primary-main);
    &:hover,
    &:focus {
        color: var(--palette-primary-dark) !important;
    }
}

.text-highlight {
    color: var(--palette-primary-dark) !important;
}

.dashboardDateWrapper {
    margin-bottom: 10px;
}

.sr-only {
    display: none;
}

// Focus style
.Mui-focusVisible:not(.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button) {
    outline: 2px #000 solid !important;
    .MuiTouchRipple-root {
        display: none;
    }
}

.MuiMenu-paper.MuiPopover-paper {
    a:hover,
    a:focus {
        color: inherit !important;
    }
}
.page-wrapper {
    padding: 5% 10%;
}
.page-topbar {
    padding-bottom: 50px;
}
h1 {
    line-height: 1.4;
}
.MuiPaginationItem-root {
    font-size: 14px !important;
}
