.block-archived {
	.block-archived-questions {
		.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary svg {
			border: 1px rgba(0, 0, 0, 0.6) solid !important;
		}
		.form-control {
			margin-left: 0 !important;
		}
	}
	.block-answer-container {
		background: transparent !important;
	}
	#questionEditorPanel > form > div {
		margin-bottom: 0 !important;
	}
	.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary, .MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
		color: #aaa !important;
	}
}
