.react-tel-input {
    font-family: Source Sans Pro, sans-serif;
    font-weight: 400;
    line-height: 1;
    .country-list {
        max-height: 200px;
        border-radius: 0;
    }
    .form-control {
        transition: none !important;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .form-control:hover {
        background-color: rgba(0, 0, 0, 0.13) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
    }
    .form-control:focus {
        background-color: var(--palette-secondary-inputHover) !important;
        border-bottom: 1px solid var(--palette-primary-A500) !important;
        box-shadow: none !important;
    }
}

.phone-input-light {
    .react-tel-input {
        .form-control:hover {
            background-color: rgba(0, 0, 0, 0.13) !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
        }
        .form-control:focus {
            background-color: #e8e8e8 !important;
            border-bottom: 1px solid var(--palette-primary-A500) !important;
        }
    }
}