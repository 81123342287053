@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/* REM override to 10px */
html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Source Sans Pro, sans-serif !important; }

* {
  box-sizing: border-box; }

a:not(.MuiButton-textSecondary):not(.MuiButton-containedPrimary):not(.MuiTypography-colorTextSecondary):not(.MuiTypography-colorSecondary):not([style*="color: rgb(255, 255, 255)"]) {
  color: var(--palette-primary-main); }
  a:not(.MuiButton-textSecondary):not(.MuiButton-containedPrimary):not(.MuiTypography-colorTextSecondary):not(.MuiTypography-colorSecondary):not([style*="color: rgb(255, 255, 255)"]):hover, a:not(.MuiButton-textSecondary):not(.MuiButton-containedPrimary):not(.MuiTypography-colorTextSecondary):not(.MuiTypography-colorSecondary):not([style*="color: rgb(255, 255, 255)"]):focus {
    color: var(--palette-primary-dark) !important; }

.text-highlight {
  color: var(--palette-primary-dark) !important; }

.dashboardDateWrapper {
  margin-bottom: 10px; }

.sr-only {
  display: none; }

.Mui-focusVisible:not(.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button) {
  outline: 2px #000 solid !important; }
  .Mui-focusVisible:not(.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button) .MuiTouchRipple-root {
    display: none; }

.MuiMenu-paper.MuiPopover-paper a:hover,
.MuiMenu-paper.MuiPopover-paper a:focus {
  color: inherit !important; }

.page-wrapper {
  padding: 5% 10%; }

.page-topbar {
  padding-bottom: 50px; }

h1 {
  line-height: 1.4; }

.MuiPaginationItem-root {
  font-size: 14px !important; }

.questionWrapper .MuiAutocomplete-tag {
  border-radius: 4px !important;
  max-width: 100% !important;
  align-items: flex-start !important; }

.questionWrapper .MuiAutocomplete-root {
  border-radius: 4px;
  background: #fff; }

.questionWrapper .MuiAutocomplete-inputRoot {
  padding: 0 0 0 8px !important; }

.questionWrapper .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  color: var(--palette-secondary-main);
  min-height: 22px;
  padding: 8px 0 !important;
  font-size: 14px !important; }
  .questionWrapper .MuiAutocomplete-inputRoot .MuiAutocomplete-input.Mui-disabled {
    color: rgba(0, 0, 0, 0.38); }

.questionWrapper .MuiInputBase-input::-webkit-input-placeholder {
  opacity: 1 !important; }

.questionWrapper .MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  font-size: 38px;
  fill: var(--palette-secondary-main); }

.questionWrapper .MuiChip-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  height: auto !important;
  border-radius: 3px !important;
  font-size: 16px !important;
  margin: 4px !important; }
  .questionWrapper .MuiChip-root .MuiChip-label {
    overflow: initial;
    white-space: normal;
    text-overflow: initial;
    line-height: 1.1;
    padding: 1px 5px 0;
    color: var(--palette-secondary-main) !important;
    word-break: break-word; }
  .questionWrapper .MuiChip-root .MuiChip-deleteIcon {
    margin-left: 0; }

.questionWrapper .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 100px; }

.questionWrapper .MuiAutocomplete-inputRoot .MuiChip-root + .MuiAutocomplete-input {
  min-width: unset;
  width: 60px;
  opacity: 0; }

.questionWrapper .MuiAutocomplete-endAdornment {
  right: 0 !important;
  top: auto !important;
  bottom: 5px !important; }
  .questionWrapper .MuiAutocomplete-endAdornment .MuiIconButton-root {
    width: 24px;
    height: 24px;
    margin: 3px;
    padding: 3px; }

.MuiAutocomplete-popper {
  padding: 0 !important;
  margin: 0 !important; }
  .MuiAutocomplete-popper ul.MuiAutocomplete-listbox {
    padding: 0 !important;
    margin: 0 !important; }
    .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li {
      font-size: 15px !important;
      padding: 4px 16px !important;
      min-height: 38px;
      background: #eeeeee; }
      .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li:hover, .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li:focus {
        background: #e3e3e3; }
      @media only screen and (max-device-width: 480px) {
        .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li {
          display: flex;
          align-items: center;
          word-break: break-all; } }
      .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li .MuiIconButton-label input[type="checkbox"] {
        width: 18px !important;
        height: 18px !important;
        min-width: 18px !important;
        min-height: 18px !important;
        max-width: 18px !important;
        max-height: 18px !important; }
      .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li .MuiIconButton-root {
        margin: -3px 6px -1px 0 !important;
        padding: 0 !important;
        width: 18px !important;
        height: 18px !important;
        min-width: 18px !important;
        min-height: 18px !important;
        max-width: 18px !important;
        max-height: 18px !important;
        background: transparent !important; }
        .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li .MuiIconButton-root:hover, .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li .MuiIconButton-root:focus {
          background: transparent !important; }
        .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li .MuiIconButton-root svg.MuiSvgIcon-root {
          margin: 0 !important;
          padding: 0 !important;
          transform: none !important;
          width: 18px !important;
          height: 18px !important;
          min-width: 18px !important;
          min-height: 18px !important;
          max-width: 18px !important;
          max-height: 18px !important; }
        .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li .MuiIconButton-root.Mui-checked svg.MuiSvgIcon-root {
          margin: -2px 0 0 0 !important;
          padding: 0 !important;
          transform: none !important;
          width: 22px !important;
          height: 22px !important;
          min-width: 22px !important;
          min-height: 22px !important;
          max-width: 22px !important;
          max-height: 22px !important; }

.MuiButton-text .MuiButton-label {
  position: relative;
  display: flex; }
  .MuiButton-text .MuiButton-label:after {
    position: absolute;
    content: "";
    display: block;
    margin: auto;
    bottom: 0;
    left: 50%;
    height: 1px;
    width: 0;
    background: transparent;
    transition: width 0.25s ease, background-color 0.25s ease, left 0.25s ease; }

.MuiButton-text.Mui-focusVisible .MuiButton-label:after {
  width: 100%;
  left: 0;
  background: var(--palette-primary-main); }

.MuiButton-text.MuiButton-textPrimary .MuiButton-label {
  color: var(--palette-primary-A500); }

.MuiButton-text.MuiButton-textPrimary svg {
  fill: var(--palette-primary-A500);
  height: 20px;
  width: 20px;
  margin: 5px; }

.MuiButton-text.MuiButton-textSizeSmall {
  margin: 5px;
  height: 32px; }

.MuiButton-textSizeLarge.MuiButton-text.MuiButton-textPrimary {
  padding: 6px 11px !important;
  height: 36px;
  display: inline-flex !important;
  align-items: center !important; }
  .MuiButton-textSizeLarge.MuiButton-text.MuiButton-textPrimary svg {
    margin-right: 0 !important;
    margin-bottom: 2px !important;
    margin-top: 4px !important; }
  .MuiButton-textSizeLarge.MuiButton-text.MuiButton-textPrimary:focus .MuiTouchRipple-root > span > span {
    background-color: var(--palette-primary-main); }

.button-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
  width: 34px !important;
  height: 34px !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important; }
  .button-icon:hover {
    background-color: rgba(229, 229, 229, 0.5) !important;
    text-decoration: none !important; }
  .button-icon.Mui-focusVisible .MuiButton-label:after {
    display: none !important; }
  .button-icon .button-icon-svg {
    display: flex !important;
    font-size: 20px;
    width: 20px;
    height: 20px;
    color: var(--palette-primary-main); }

.MuiIconButton-root.Mui-disabled svg {
  fill: var(--palette-text-disabled) !important; }

.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-subtitle1 {
  font-size: 24px; }

.MuiPickersMonth-root.MuiPickersMonth-monthSelected.MuiTypography-h5,
.MuiPickersYear-root.MuiPickersYear-yearSelected.MuiTypography-h5 {
  font-size: 24px !important; }

.MuiIconButton-root.MuiCheckbox-root {
  margin: -7px 5px 7px -7px;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  padding: 9px; }
  .MuiIconButton-root.MuiCheckbox-root svg {
    fill: var(--palette-light);
    border: 1px rgba(0, 0, 0, 0.54) solid;
    width: 20px !important;
    height: 20px !important;
    font-size: 24px;
    border-radius: 3px !important; }
    .MuiIconButton-root.MuiCheckbox-root svg path {
      display: none; }
  .MuiIconButton-root.MuiCheckbox-root.Mui-checked svg {
    margin-top: -3px;
    margin-left: 1px;
    fill: var(--palette-primary-main);
    width: 27px !important;
    height: 27px !important;
    border: none; }
    .MuiIconButton-root.MuiCheckbox-root.Mui-checked svg path {
      display: block; }
  .MuiIconButton-root.MuiCheckbox-root.Mui-disabled svg {
    fill: rgba(206, 217, 224, 0.5) !important;
    cursor: not-allowed;
    border-color: var(--palette-action-disabled); }
  .MuiIconButton-root.MuiCheckbox-root.Mui-disabled + .MuiFormControlLabel-label {
    color: rgba(0, 0, 0, 0.38); }
  .MuiIconButton-root.MuiCheckbox-root .MuiTouchRipple-root {
    overflow: visible; }
  .MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary svg {
    border: 1px var(--palette-light) solid; }
  .MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary.Mui-checked svg {
    fill: var(--palette-light);
    border: 1px transparent solid;
    width: 28px !important;
    height: 28px !important; }

.MuiCheckbox-root.Mui-disabled + .MuiFormControlLabel-label {
  color: rgba(0, 0, 0, 0.38); }

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart .MuiIconButton-root.MuiCheckbox-root {
  margin: -7px 5px 7px 5px; }

@media only screen and (max-device-width: 767px) {
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding-bottom: 8px; } }

*:focus {
  outline: none; }

input {
  font-size: 1.7rem !important; }

label {
  font-size: 1.7rem !important; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; }

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; }

@media only screen and (min-width: 1004px) {
  .MuiContainer-maxWidthLg {
    max-width: 1054px !important; } }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield; }

.MuiFormLabel-root {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  right: 22px; }

.MuiFormHelperText-contained {
  margin: 0 !important; }

.MuiFormControl-root {
  margin-bottom: 12px; }

.Mui-disabled {
  cursor: default; }

.MuiFilledInput-root.Mui-disabled,
textarea[disabled] {
  pointer-events: none;
  opacity: 0.4; }

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  background-color: rgba(0, 0, 0, 0.06); }

.MuiFilledInput-underline:after {
  border-bottom-width: 1px !important; }

.Mui-focused.MuiFilledInput-underline:after {
  border-bottom-color: var(--palette-primary-A500) !important;
  transform: scaleX(1); }

.Mui-focusVisible .MuiIconButton-label span {
  border: 1px var(--palette-secondary-dark) solid !important; }

.MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorSecondary.MuiFilledInput-colorSecondary {
  color: var(--palette-light) !important;
  background-color: var(--palette-secondary-input) !important; }
  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorSecondary.MuiFilledInput-colorSecondary:hover {
    background-color: var(--palette-secondary-inputHover) !important; }

.MuiFormLabel-root.MuiFormLabel-colorSecondary.Mui-focused.MuiInputLabel-filled,
.MuiFormLabel-colorSecondary.Mui-focused {
  color: var(--palette-light) !important; }

.MuiFormLabel-root.MuiInputLabel-filled,
.MuiFormLabel-root.MuiFormLabel-colorSecondary.MuiInputLabel-filled {
  margin-left: 0 !important;
  line-height: 1.1; }

.MuiInputAdornment-positionEnd svg {
  width: 24px;
  height: 24px; }

.MuiFilledInput-adornedEnd {
  padding-right: 4px !important; }

.MuiIconButton-edgeEnd {
  margin-right: 0 !important; }

.api-key-styled .MuiFormLabel-root {
  margin-left: 0; }

.api-key-styled .MuiFormLabel-root.Mui-error,
.api-key-styled .MuiFormHelperText-root.Mui-error {
  color: var(--palette-text-primary); }

.api-key-styled .MuiInput-underline.Mui-error:after {
  border-bottom-color: var(--palette-text-primary); }

.filled-form-control .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-filled {
  color: var(--palette-text-primary) !important; }

.filled-form-control .MuiInputBase-root.MuiFilledInput-root {
  color: var(--palette-text-primary) !important; }

.MuiFormHelperText-root {
  font-size: 15px !important; }

.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 8px) scale(0.85) !important; }

.MuiInputLabel-filled {
  text-align: left; }

.global-input-wrapper {
  min-height: 80px; }

textarea {
  font-size: 17px;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 57px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 18px 16px 16px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  resize: vertical; }
  textarea:hover {
    background-color: rgba(0, 0, 0, 0.13); }
  textarea:focus {
    border-bottom-color: var(--palette-primary-main); }

.MuiInputBase-root textarea:hover {
  background-color: transparent; }

.narrow-input-wrapper .global-input-wrapper {
  min-height: 73px; }

.narrow-input-wrapper label {
  font-size: 1.7rem !important;
  line-height: 1.2; }

.narrow-input-wrapper .MuiFilledInput-input {
  padding: 20px 12px 8px !important;
  border-radius: 5px 5px 0 0; }

.narrow-input-wrapper .MuiInputLabel-filled {
  transform: translate(12px, 14px) scale(1); }

.narrow-input-wrapper .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 3px) scale(0.7) !important; }

.narrow-input-wrapper .MuiFormHelperText-root {
  font-size: 15px !important; }

.narrow-input-wrapper .MuiInputBase-input::-webkit-input-placeholder {
  font-size: 1.7rem !important; }

.narrow-input-wrapper .MuiFilledInput-underline.Mui-error:after,
.narrow-input-wrapper .MuiFilledInput-colorSecondary.MuiFilledInput-underline:after {
  transform: scale(1);
  border-bottom-color: #647185; }

.narrow-input-wrapper .MuiInputAdornment-positionEnd svg {
  width: 18px;
  height: 18px; }

.MuiList-root .Mui-selected .MuiSvgIcon-root {
  display: none; }

.MuiList-root.MuiMenu-list .MuiTypography-root,
.MuiList-root.MuiMenu-list .MuiListItem-root {
  white-space: normal; }

.MuiLinearProgress-barColorPrimary {
  background-color: var(--palette-secondary-A100); }

.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary,
.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
  color: var(--palette-light) !important; }

.MuiButtonBase-root.MuiRadio-root + .MuiFormControlLabel-label {
  padding: 3px !important; }

.radio-big.MuiFormControlLabel-root {
  margin-top: 5px;
  margin-bottom: 5px; }

.radio-big .MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 18px;
  font-weight: 400; }

.radio-big .MuiRadio-root .MuiSvgIcon-root {
  width: 22px;
  height: 22px; }

.selectStyle .MuiSelect-selectMenu {
  padding: 0 12px 5px 0 !important;
  min-height: 48px;
  display: flex;
  align-items: center; }

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  padding-left: 14px; }

#filled-select-currency-label {
  transform: translate(12px, 10px) scale(0.75) !important; }

.admin-select-outlined .MuiSelect-root.MuiSelect-select {
  border: 1px #979797 solid;
  background-color: #fff !important;
  border-radius: 4px !important;
  padding: 8px 39px 8px 12px;
  font-size: 16px; }
  .admin-select-outlined .MuiSelect-root.MuiSelect-select:focus {
    border-radius: 4px !important; }

.admin-select-outlined .MuiSelect-icon {
  width: 24px;
  height: 24px; }

.admin-select-outlined.admin-select-outlined-line .MuiInput-underline:after,
.admin-select-outlined.admin-select-outlined-line .MuiInput-underline:before {
  display: none !important; }

.admin-select-outlined.admin-select-outlined-line .MuiSelect-icon {
  border-left: 1px #979797 solid;
  padding: 0 5px;
  width: 34px;
  height: 24px; }

.admin-select-outlined.admin-select-outlined-line .MuiSelect-root.MuiSelect-select {
  padding: 8px 49px 8px 12px; }

.MuiSlider-root {
  cursor: e-resize !important; }

@media only screen and (max-device-width: 640px) {
  .MuiTableCell-root {
    padding: 8px; } }

@media only screen and (max-device-width: 640px) {
  .tabs-as-chips {
    width: 126%;
    margin: 0 -13%; } }

@media only screen and (max-width: 640px) {
  .tabs-as-chips {
    width: 126%;
    margin: 0 -13%; } }

.tabs-as-chips button.MuiTab-root {
  color: var(--palette-text-primary);
  border: 1px solid rgba(0, 0, 0, 0.42);
  padding: 8px 0;
  font-size: 15px;
  border-radius: 3px;
  background-color: #fff;
  min-width: 100px !important;
  min-height: 0 !important;
  margin: 8px 16px 8px 0;
  line-height: 1 !important; }
  @media only screen and (min-device-width: 640px) {
    .tabs-as-chips button.MuiTab-root {
      max-width: 100%; } }
  @media only screen and (min-width: 640px) {
    .tabs-as-chips button.MuiTab-root {
      max-width: 100%; } }
  .tabs-as-chips button.MuiTab-root .MuiTab-wrapper {
    display: block; }
  .tabs-as-chips button.MuiTab-root .tabs-as-chips-label {
    font-size: 15px;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
    line-height: 1 !important; }
    @media only screen and (max-device-width: 640px) {
      .tabs-as-chips button.MuiTab-root .tabs-as-chips-label {
        font-size: 12px; } }
    @media only screen and (max-width: 640px) {
      .tabs-as-chips button.MuiTab-root .tabs-as-chips-label {
        font-size: 12px; } }
  .tabs-as-chips button.MuiTab-root.Mui-selected {
    border-color: #e0e0e0;
    background-color: #e0e0e0;
    padding-left: 20px;
    position: relative; }
    .tabs-as-chips button.MuiTab-root.Mui-selected::before {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: 16px;
      transform: translateY(-50%) rotate(45deg);
      height: 10px;
      width: 4px;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000; }

.tabs-as-chips .MuiTabs-indicator {
  display: none !important; }

.tabs-as-chips .MuiTab-textColorInherit {
  opacity: 1; }

.tabs-as-chips .MuiSvgIcon-fontSizeSmall {
  font-size: 30px;
  color: var(--palette-primary-main); }

@media only screen and (min-device-width: 640px) {
  .tabs-as-chips .MuiTabScrollButton-root {
    height: 33px;
    margin-top: 8px;
    border-radius: 3px; }
    .tabs-as-chips .MuiTabScrollButton-root.Mui-disabled {
      display: none; } }

@media only screen and (min-width: 640px) {
  .tabs-as-chips .MuiTabScrollButton-root {
    height: 33px;
    margin-top: 8px;
    border-radius: 3px; }
    .tabs-as-chips .MuiTabScrollButton-root.Mui-disabled {
      display: none; } }

.admin-import {
  padding-bottom: 70px !important;
  height: auto !important; }
  .admin-import.admin-fullheight-wrapper .paper-inner {
    overflow: visible !important; }
    .admin-import.admin-fullheight-wrapper .paper-inner .admin-import-table {
      width: calc(100% + 64px);
      margin: 0 -32px;
      border-top: 1px solid #e0e0e0; }

.admin-table-fullheight.admin-import-table {
  height: 100% !important;
  min-height: 100% !important;
  overflow: auto; }

.infoAlert {
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 1px 1px 0 var(--palette-primary-main);
  background-color: var(--palette-primary-A300);
  display: flex;
  color: var(--palette-secondary-dark);
  align-items: center;
  width: 100% !important;
  margin: 30px 0 !important; }
  @media only screen and (max-device-width: 640px) {
    .infoAlert {
      flex-wrap: wrap; } }
  .infoAlert.infoAlertInPaper {
    width: calc(100% - 122px) !important;
    margin: 65px auto 0 !important; }
    @media only screen and (max-device-width: 640px) {
      .infoAlert.infoAlertInPaper {
        width: calc(100% - 64px) !important;
        margin: 37px auto 0 !important; } }
  .infoAlert svg {
    font-size: 30px;
    margin-right: 25px;
    color: var(--palette-primary-main); }
    @media only screen and (max-device-width: 640px) {
      .infoAlert svg {
        margin-bottom: 10px; } }

.block-archived .block-archived-questions .MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary svg {
  border: 1px rgba(0, 0, 0, 0.6) solid !important; }

.block-archived .block-archived-questions .form-control {
  margin-left: 0 !important; }

.block-archived .block-answer-container {
  background: transparent !important; }

.block-archived #questionEditorPanel > form > div {
  margin-bottom: 0 !important; }

.block-archived .MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary, .block-archived .MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
  color: #aaa !important; }

.birthDateClass label + .MuiInput-formControl {
  margin-top: 0;
  padding-top: 27px; }

.birthDateClass .MuiInputBase-input.MuiInput-input {
  margin: 0;
  padding: 0 0 10px 0; }

.birthDateClass .MuiInputBase-formControl {
  color: white;
  position: relative;
  font-size: 16px;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.birthDateClass .MuiInputBase-formControl:hover {
  background-color: rgba(0, 0, 0, 0.13); }

.birthDateClass .MuiInput-underline:after {
  border-bottom-width: 1px !important; }

.birthDateClass .MuiFilledInput-underline:hover:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87); }

.birthDateClass .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important; }

.bodyImitateModal .menuButton {
  display: none !important; }

@media only screen and (max-device-width: 959px) {
  .imitateModalOnMobileClosed {
    display: none; } }

@media only screen and (max-width: 959px) {
  .imitateModalOnMobileClosed {
    display: none; } }

@media only screen and (max-device-width: 959px) and (-webkit-min-device-pixel-ratio: 2) {
  .imitateModalOnMobileClosed {
    display: none; } }

.bodyImitateModal .imitateModalOnMobileClosed,
.bodyImitateModal .imitateModalOnMobileOpened {
  display: block;
  padding-top: 28px; }
  @media only screen and (max-width: 959px) {
    .bodyImitateModal .imitateModalOnMobileClosed,
    .bodyImitateModal .imitateModalOnMobileOpened {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--palette-light);
      overflow: auto;
      padding: 50px 16px !important; } }
  @media only screen and (max-device-width: 959px) {
    .bodyImitateModal .imitateModalOnMobileClosed,
    .bodyImitateModal .imitateModalOnMobileOpened {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--palette-light);
      overflow: auto;
      padding: 50px 16px !important; } }
  @media only screen and (max-device-width: 959px) and (-webkit-min-device-pixel-ratio: 2) {
    .bodyImitateModal .imitateModalOnMobileClosed,
    .bodyImitateModal .imitateModalOnMobileOpened {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--palette-light);
      overflow: auto;
      padding: 50px 16px !important; } }
  .bodyImitateModal .imitateModalOnMobileClosed #closeButtonWrapper,
  .bodyImitateModal .imitateModalOnMobileOpened #closeButtonWrapper {
    position: fixed;
    top: 0;
    left: 0;
    justify-content: flex-end;
    background: var(--palette-light);
    width: 100%;
    z-index: 4; }
  .bodyImitateModal .imitateModalOnMobileClosed #closeButton,
  .bodyImitateModal .imitateModalOnMobileOpened #closeButton {
    background: var(--palette-light);
    display: block;
    border: none;
    padding: 6px; }
    .bodyImitateModal .imitateModalOnMobileClosed #closeButton .MuiSvgIcon-root,
    .bodyImitateModal .imitateModalOnMobileOpened #closeButton .MuiSvgIcon-root {
      font-size: 24px; }

@media only screen and (min-device-width: 959px) {
  .imitateModalOnMobileOpened,
  .imitateModalOnMobileClosed {
    display: block !important; } }

@media only screen and (min-width: 959px) {
  .imitateModalOnMobileOpened,
  .imitateModalOnMobileClosed {
    display: block !important; } }

@media only screen and (min-width: 959px) and (-webkit-min-device-pixel-ratio: 2) {
  .imitateModalOnMobileOpened,
  .imitateModalOnMobileClosed {
    display: block !important; } }

@media only screen and (min-width: 640px) and (min-width: 959px) {
  .observational-protocol-menu-details {
    display: grid;
    grid-template-columns: 50% 50%; } }

#chartWrapper {
  display: block;
  width: calc(100vw - 30px);
  overflow: auto;
  white-space: nowrap; }
  @media only screen and (max-device-width: 960px) {
    #chartWrapper {
      width: 100%;
      margin: 0 -16px;
      padding: 0; } }

.chart-wrapper {
  position: relative; }

[id^="chart"] svg,
#chart svg {
  overflow: visible; }

@keyframes pulse-ring {
  0% {
    opacity: 0;
    r: 0; }
  100% {
    opacity: 0.3;
    r: 30; } }

.circle-pulse {
  opacity: 0.3;
  transform: translate(40, 30);
  animation: pulse-ring 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }

.x-top-info .tick {
  position: relative; }

.boxScoreLabel {
  transition: all 0.3s ease;
  color: var(--palette-light);
  background-color: var(--palette-primary-main);
  height: 50px;
  min-width: 50px;
  padding: 0 5px;
  border-radius: 8px;
  margin: 0 auto; }

.boxDueLabel {
  transition: all 0.3s ease;
  color: var(--palette-light);
  background-color: #3b3c43;
  height: 50px;
  min-width: 57px;
  padding: 0 5px;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .boxDueLabel .material-icons {
    margin: -4px auto 0;
    color: #5c5d65;
    font-size: 44px; }
  .boxDueLabel .boxDueLabelDate {
    width: 44px;
    margin-left: -44px;
    padding: 1px 2px 0 0;
    font-size: 11px;
    color: white; }

.xTopLabel {
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  margin: 0 auto; }

.labelHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.xTopLabelText {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font: normal normal 600 12px/18px Source Sans Pro;
  margin: 0 auto 4px; }
  .xTopLabelText:hover .labelHeaderUpcoming::before {
    content: "i";
    display: block;
    position: absolute;
    left: -20px;
    border-radius: 20px;
    width: 15px;
    height: 15px;
    color: #fff;
    background: #3a86ff;
    line-height: 15px;
    font-weight: 600; }

.dateLabel {
  white-space: nowrap;
  display: block; }

.xTopLabelFuture .boxDueLabel {
  background-color: #1f2029; }
  .xTopLabelFuture .boxDueLabel .material-icons {
    color: #383841; }

.xTopLabelFuture .xTopLabelText {
  color: #a1a1a1 !important; }

@media only screen and (max-device-width: 960px) {
  [id^="chart"],
  #chart {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (max-width: 960px) {
  [id^="chart"],
  #chart {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (max-device-width: 568px) {
  [id^="chart"],
  #chart {
    padding-left: 0; } }

@media only screen and (max-width: 568px) {
  [id^="chart"],
  #chart {
    padding-left: 0; } }

.chartGraph::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background-color: #fff; }

.chartGraph::-webkit-scrollbar {
  height: 15px;
  border-radius: 15px;
  background-color: #1f2029; }

.chartGraph::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 2px #fff solid;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #383841; }

.rootChart .Mui-focusVisible:not(.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button) {
  outline: 2px #fff solid !important; }

.chart-side-info {
  padding: 40px 20px;
  margin-bottom: 6px !important; }
  @media only screen and (min-device-width: 960px) {
    .chart-side-info {
      padding: 10px 10px 40px 2.8%;
      border-left: 1px solid #d7d7d7;
      box-shadow: inset 1px 0 3px -2px rgba(0, 0, 0, 0.4); } }
  @media only screen and (min-width: 960px) {
    .chart-side-info {
      padding: 10px 10px 40px 2.8%;
      border-left: 1px solid #d7d7d7;
      box-shadow: inset 1px 0 3px -2px rgba(0, 0, 0, 0.4); } }

.gridGroup > .domain,
.gridGroupY > .domain {
  display: none; }

.gridGroup .tick:first-of-type line,
.gridGroupY .tick:first-of-type line {
  fill: none;
  stroke: none; }

.lineChart .xAxisGroup > .tick:first-of-type {
  transform: translateX(10px); }

.lineChart .xAxisGroup > .tick:last-of-type {
  transform: translateX(calc(100% - 10px)); }

.lineChart .xAxisGroup > .tick:first-of-type:last-of-type {
  transform: translateX(50%); }

.lineChart .xAxisGroup > .tick.tickLong:first-of-type {
  transform: translateX(50px); }

.lineChart .xAxisGroup > .tick.tickLong:last-of-type {
  transform: translateX(calc(100% - 44px)); }

.lineChart circle,
.lineChart foreignObject,
.lineChart div,
.lineChart span {
  -webkit-tap-highlight-color: transparent; }

@media only screen and (max-width: 568px) {
  .container-questions-grid {
    border: 0; } }

.container-questions-grid .MuiGrid-grid-md-6,
.container-questions-grid .MuiGrid-grid-sm-6,
.container-questions-grid .MuiGrid-grid-lg-6 {
  padding: 12px 20px 7px 0;
  position: relative; }
  .container-questions-grid .MuiGrid-grid-md-6:after,
  .container-questions-grid .MuiGrid-grid-sm-6:after,
  .container-questions-grid .MuiGrid-grid-lg-6:after {
    content: "";
    width: calc(100% - 20px);
    height: 2px;
    background-color: var(--palette-primary-A100);
    position: absolute;
    top: 0;
    left: 0; }
  @media only screen and (max-width: 568px) {
    .container-questions-grid .MuiGrid-grid-md-6,
    .container-questions-grid .MuiGrid-grid-sm-6,
    .container-questions-grid .MuiGrid-grid-lg-6 {
      padding: 10px 0;
      display: flex;
      width: 100%; }
      .container-questions-grid .MuiGrid-grid-md-6:after,
      .container-questions-grid .MuiGrid-grid-sm-6:after,
      .container-questions-grid .MuiGrid-grid-lg-6:after {
        display: none; } }

.container-questions-grid .MuiTypography-root {
  margin-bottom: 0 !important; }

.container-questions-grid + .container-questions-grid {
  border-top: 0;
  border-bottom: 0; }

.container-questions-grid .MuiIconButton-root.MuiCheckbox-root {
  margin-bottom: 0 !important; }

.container-questions-range-header {
  width: 100%;
  padding-bottom: 20px; }
  .container-questions-range-header .MuiGrid-grid-md-6:first-child,
  .container-questions-range-header .MuiGrid-grid-sm-6:first-child,
  .container-questions-range-header .MuiGrid-grid-lg-6:first-child {
    padding-right: 20px; }

.container-questions-range {
  padding-bottom: 20px; }
  .container-questions-range .MuiGrid-grid-md-6:first-child,
  .container-questions-range .MuiGrid-grid-sm-6:first-child,
  .container-questions-range .MuiGrid-grid-lg-6:first-child {
    padding-right: 20px; }

.container-radiogroup-grid {
  width: 100%; }
  .container-radiogroup-grid > .MuiFormGroup-root {
    width: 100%; }
    .container-radiogroup-grid > .MuiFormGroup-root > .MuiFormGroup-root {
      width: 100%;
      display: flex; }
      @media screen and (min-width: 960px) {
        .container-radiogroup-grid > .MuiFormGroup-root > .MuiFormGroup-root > .MuiFormControlLabel-root,
        .container-radiogroup-grid > .MuiFormGroup-root > .MuiFormGroup-root > .MuiGrid-root {
          display: flex;
          width: 100%;
          padding: 0 !important;
          margin-right: 0; } }

.admin-question-container {
  border: 2px #ddd solid;
  padding: 20px;
  margin-bottom: 32px;
  flex-direction: column;
  display: flex; }
  .admin-question-container .fileUploaderWrapper {
    padding: 3px 0 10px; }

.admin-question-container-50 {
  display: flex;
  flex-direction: row !important;
  margin: 0 -10px;
  width: calc(100% + 20px) !important; }

.admin-question-container .MuiGrid-grid-sm-6,
.admin-question-container-50 > div {
  max-width: 50%;
  padding: 9px 10px 2px;
  flex: 0 0 50%;
  position: relative; }
  .admin-question-container .MuiGrid-grid-sm-6::before,
  .admin-question-container-50 > div::before {
    content: "";
    height: 2px;
    width: calc(100% - 20px);
    background-color: #ddd;
    position: absolute;
    top: 2px;
    left: 10px; }
  .admin-question-container .MuiGrid-grid-sm-6 .MuiButtonBase-root.MuiRadio-root + .MuiFormControlLabel-label,
  .admin-question-container-50 > div .MuiButtonBase-root.MuiRadio-root + .MuiFormControlLabel-label {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .admin-question-container .MuiGrid-grid-sm-6 .MuiRadio-root,
  .admin-question-container-50 > div .MuiRadio-root {
    padding-top: 2px !important; }
  .admin-question-container .MuiGrid-grid-sm-6 .radio-select-question-wrapper,
  .admin-question-container-50 > div .radio-select-question-wrapper {
    margin-top: 0 !important; }
  .admin-question-container .MuiGrid-grid-sm-6 h6,
  .admin-question-container-50 > div h6 {
    margin-bottom: 0 !important; }

.admin-question-container .range-component .MuiGrid-grid-sm-6 {
  padding: 5px 0; }
  .admin-question-container .range-component .MuiGrid-grid-sm-6::before {
    display: none; }

.survey-viewer .admin-question-container {
  border: none;
  padding: 0;
  margin-bottom: 32px;
  flex-direction: row;
  display: flex; }

.survey-viewer .admin-question-container-50 {
  display: block;
  margin: 0;
  width: 100% !important; }

.survey-viewer .admin-question-container .MuiGrid-grid-sm-6,
.survey-viewer .admin-question-container-50 > div {
  max-width: 100%;
  padding: 0 0 10px;
  flex: 0 0 100%;
  position: relative; }
  .survey-viewer .admin-question-container .MuiGrid-grid-sm-6::before,
  .survey-viewer .admin-question-container-50 > div::before {
    display: none; }

.block-archived-questions .admin-question-container {
  border: none;
  padding: 0;
  margin-bottom: 32px;
  flex-direction: row;
  display: flex; }

.block-archived-questions .admin-question-container .MuiGrid-grid-sm-6::before,
.block-archived-questions .admin-question-container-50 > div::before {
  display: none; }

.admin-question-description {
  padding: 40px 32px;
  background-color: var(--palette-primary-A200);
  margin: 0 0 32px; }
  .admin-question-description .MuiTypography-subtitle1 {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .admin-question-description .MuiTypography-subtitle2 {
    font-size: 18px;
    letter-spacing: 1px; }

.admin-question-instruction {
  margin: 0 0 16px !important; }
  .admin-question-instruction .MuiTypography-subtitle2 {
    margin: 0 !important; }

.admin-question-title {
  margin-bottom: 10px !important; }
  .admin-question-title .MuiTypography-subtitle1 {
    font-size: 17px;
    letter-spacing: 1px;
    color: #011020 !important;
    font-weight: 700;
    margin-bottom: 0; }

.error-page {
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.fade-in {
  opacity: 1;
  height: auto;
  -webkit-animation: fadeIn 1s; }

.fade-out {
  transition: opacity 4s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-animation: fadeOut 1s; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.graph-settings-drawer .MuiInputBase-root {
  font-size: 16px; }

.graph-settings-drawer .MuiFilledInput-underline:after,
.graph-settings-drawer .MuiInput-underline:after,
.graph-settings-drawer .MuiInput-underline:before {
  display: none; }

.graph-settings-drawer .MuiSelect-select {
  border-bottom: solid 1px #979797; }
  .graph-settings-drawer .MuiSelect-select:focus {
    background: transparent; }

.graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) {
  border-radius: 4px 4px 0 0 !important;
  background: #ebebeb !important; }
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiInputBase-root.MuiFilledInput-root,
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiInputBase-input.MuiFilledInput-input {
    border-radius: 4px 4px 0 0 !important;
    background: #ebebeb !important; }
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiInputBase-input.MuiFilledInput-input {
    border-bottom: solid 1px #979797; }
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiInputBase-root {
    background: #ebebeb !important;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1); }
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiFormLabel-root.Mui-focused {
    color: var(--palette-primary-main); }
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiIconButton-root:hover {
    color: var(--palette-primary-main) !important; }
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiFormHelperText-root.Mui-error {
    background-color: #fff !important; }
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiInputBase-input,
  .graph-settings-drawer .MuiTextField-root.MuiFormControl-root:not(fieldset) .MuiInputLabel-root {
    color: #000; }

.graph-settings-drawer fieldset.MuiFormControl-root {
  border-radius: 4px 4px 0 0 !important; }

.graph-settings-drawer .MuiSelect-icon {
  width: 24px;
  height: 24px;
  color: #000;
  right: 10px; }

.graph-settings-drawer .select-with-border .MuiFormControl-root {
  background-color: #fff !important;
  color: #000 !important; }

.graph-settings-drawer .select-with-border .MuiSelect-root.MuiInput-input {
  border: 1px #979797 solid;
  background-color: #fff !important;
  border-radius: 4px !important;
  padding-right: 39px; }
  .graph-settings-drawer .select-with-border .MuiSelect-root.MuiInput-input:focus {
    border-radius: 4px !important; }

.graph-settings-drawer .select-with-border .MuiInput-underline:before,
.graph-settings-drawer .select-with-border .MuiInput-underline .Mui-focused:after, .graph-settings-drawer .select-with-border .MuiInput-underline:after {
  display: none !important; }

.graph-settings-drawer .select-with-border .MuiSelect-icon {
  width: 24px;
  height: 24px;
  color: #000;
  right: 10px; }

.graph-settings-drawer .select-with-border .MuiInput-formControl {
  margin-top: 0 !important;
  color: #000 !important;
  border-radius: 4px !important;
  background: #fff !important; }

.graph-settings-drawer .select-with-border .MuiInputLabel-formControl {
  margin-top: -15px !important;
  transform: opacity(0); }
  .graph-settings-drawer .select-with-border .MuiInputLabel-formControl.Mui-focused {
    transform: translate(0, 24px) scale(1) !important;
    color: #000 !important; }
  .graph-settings-drawer .select-with-border .MuiInputLabel-formControl.MuiFormLabel-filled {
    display: none; }

.graph-settings-drawer .select-with-label-outside .select-with-border .MuiInputLabel-formControl.Mui-focused {
  display: block !important;
  color: var(--palette-primary-main) !important;
  margin-top: -47px !important;
  margin-left: 0;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.5px;
  font-family: Source Sans Pro, sans-serif; }

.graph-settings-drawer .select-centered .MuiSelect-icon {
  top: 0 !important; }

.graph-settings-drawer .cohort-dashed-border {
  margin: 16px 4px;
  width: calc(100% - 8px);
  padding: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23BEBEBEFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px; }

.graph-settings-drawer .input-h4 .MuiFormControl-root .MuiInputBase-input {
  font-size: 20px !important;
  font-weight: 600;
  letter-spacing: 0.25px; }

.graph-settings-drawer .cohort-question-edit-box {
  margin: 16px 0;
  width: calc(100% - 8px);
  border-radius: 4px;
  padding: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23BEBEBEFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }

.graph-settings-drawer .cohort-question-box {
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-left: 10px #d9e6fc solid; }

.graph-settings-drawer .buttons-box-graph {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0 -5px; }
  .graph-settings-drawer .buttons-box-graph button {
    padding: 4px 24px !important;
    min-width: 92px; }

.graph-settings-drawer .MuiTypography-h4 {
  font-size: 18px;
  letter-spacing: 0.31px !important;
  font-weight: bold; }

.mainHeaderMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  align-items: center; }

.mainHeaderMenuMobile {
  position: relative;
  z-index: 1001; }
  .mainHeaderMenuMobile.navOpened {
    position: fixed;
    top: 0;
    right: 0;
    display: block;
    min-width: 220px;
    max-width: 220px;
    min-height: 100vh;
    max-height: 100vh;
    background-color: var(--palette-secondary-dark);
    z-index: 1;
    transition: all 0.2s ease;
    overflow: hidden; }
    .mainHeaderMenuMobile.navOpened *:focus {
      outline: 2px #fff solid !important; }
    .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-menuWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start; }
    .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonLink {
      color: #fff !important;
      text-align: left;
      margin-bottom: 10px; }
      .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonLink:hover, .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonLink:focus {
        color: #fff !important; }
        .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonLink:hover svg, .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonLink:focus svg {
          color: #fff !important; }
    .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonIcon {
      padding: 10px;
      color: #fff !important; }
      .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonIcon svg {
        color: #fff !important; }
      .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonIcon:hover, .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonIcon:focus {
        color: #fff !important; }
        .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonIcon:hover svg, .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-buttonIcon:focus svg {
          color: #fff !important; }
    .mainHeaderMenuMobile.navOpened .mainHeaderMenuMobile-separator {
      width: 100%;
      height: 1px;
      margin: 10px 0;
      opacity: 0.2; }
  .mainHeaderMenuMobile.navClosed {
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--palette-secondary-dark);
    min-height: 100vh;
    max-height: 100vh;
    min-width: 0;
    max-width: 0;
    width: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden; }
  .mainHeaderMenuMobile > div {
    min-width: 220px; }

.rdsu-image img {
  max-width: 100%;
  max-height: 100%; }

.react-dropzone-s3-uploader {
  z-index: 11; }

.fileUploaderWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center; }

.rdsu-filename {
  word-break: break-all; }

.dropzone-file {
  margin-top: 32px; }

.file-question-wrapper {
  padding: 32px 16px;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .file-question-wrapper {
      padding: 16px 8px;
      font-size: 12px; }
      .file-question-wrapper .MuiButton-root {
        font-size: 12px;
        height: auto;
        padding: 5px; } }

.input-with-tooltip {
  position: relative; }
  .input-with-tooltip .MuiFilledInput-adornedEnd {
    padding-right: 46px !important; }
  .input-with-tooltip .input-with-tooltip-svg {
    position: absolute;
    top: 24px;
    right: 25px; }
  .input-with-tooltip svg {
    width: 24px;
    height: 24px; }

.label-paper-inner {
  width: calc(100% + 64px) !important;
  margin: 0 -32px; }

.component-label-paper {
  min-width: 200px;
  margin-left: 32px;
  margin-right: 32px;
  transform: translateY(-50%);
  display: table;
  min-height: 48px;
  margin-bottom: -12px;
  background-color: var(--palette-secondary-main) !important; }

.component-label-paper-title {
  padding: 14px 24px;
  text-align: center;
  color: #fff;
  font-size: 20px !important;
  line-height: 1 !important;
  letter-spacing: 0.25px !important;
  font-weight: 700 !important; }

.paper-unstyled.MuiPaper-root {
  box-shadow: none !important; }

.paper-inner .paper-unstyled .paper-inner {
  width: calc(100% + 64px);
  margin: 0 -32px; }
  .paper-inner .paper-unstyled .paper-inner .composite-label-paper-content {
    margin: 0 !important; }
    .paper-inner .paper-unstyled .paper-inner .composite-label-paper-content .admin-import-table {
      margin: 0 !important;
      width: auto !important; }

.splash-screen {
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .splash-screen__logo {
    min-width: 44rem;
    min-height: 12.8rem;
    background-repeat: no-repeat;
    margin-top: 21.3rem;
    padding-right: 1rem; }

.react-tel-input {
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400;
  line-height: 1; }
  .react-tel-input .country-list {
    max-height: 200px;
    border-radius: 0; }
  .react-tel-input .form-control {
    transition: none !important;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .react-tel-input .form-control:hover {
    background-color: rgba(0, 0, 0, 0.13) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important; }
  .react-tel-input .form-control:focus {
    background-color: var(--palette-secondary-inputHover) !important;
    border-bottom: 1px solid var(--palette-primary-A500) !important;
    box-shadow: none !important; }

.phone-input-light .react-tel-input .form-control:hover {
  background-color: rgba(0, 0, 0, 0.13) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important; }

.phone-input-light .react-tel-input .form-control:focus {
  background-color: #e8e8e8 !important;
  border-bottom: 1px solid var(--palette-primary-A500) !important; }

.select-component {
  min-width: 280px !important;
  width: 100%; }
  .select-component .MuiFormControlLabel-root {
    margin-right: 0; }

.dropdown-component {
  min-width: 280px !important;
  width: 100%; }
  .dropdown-component .MuiFormControlLabel-root {
    margin-right: 0; }

.react-select-styled__input-container {
  display: inline-flex !important; }

.react-select-styled__control {
  border-radius: 5px 5px 0 0;
  background-color: #ebebeb;
  border: none;
  border-bottom: 1px #d2d2d2 solid;
  box-shadow: none !important; }

.react-select-styled__placeholder {
  color: #4a4a4a !important; }

.react-select-styled__indicator-separator {
  display: none; }

.react-select-styled__dropdown-indicator {
  position: relative; }
  .react-select-styled__dropdown-indicator svg {
    display: none; }
  .react-select-styled__dropdown-indicator::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    margin-top: 2px; }

.react-select-styled__menu-portal {
  z-index: 99999; }
  .react-select-styled__menu-portal .react-select-styled__menu {
    margin: 0 !important; }
  .react-select-styled__menu-portal .react-select-styled__option {
    background: #fff !important;
    color: #4a4a4a !important;
    font-size: 17px; }
    .react-select-styled__menu-portal .react-select-styled__option:hover, .react-select-styled__menu-portal .react-select-styled__option:active, .react-select-styled__menu-portal .react-select-styled__option:focus {
      background: #ededed !important;
      color: #4a4a4a !important; }

.react-select-styled--is-disabled .react-select-styled__control--is-disabled {
  background-color: #ebebeb;
  border-bottom: 1px #d4d4d4 solid; }

.react-select-styled--is-disabled .react-select-styled__placeholder {
  color: #bababa !important; }

.react-select-styled--is-disabled .react-select-styled__dropdown-indicator::after {
  border-top: 5px solid #a4a4a4; }

.range-component {
  padding-bottom: 10px; }
  .range-component .MuiTypography-subtitle1 {
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 8px 8px 0 0; }
  .range-component .MuiInputBase-root {
    width: 100%; }
  .range-component .MuiSelect-select {
    border-radius: 5px 5px 0 0;
    background-color: #ebebeb;
    border: none;
    border-bottom: 1px #d2d2d2 solid;
    box-shadow: none !important; }
  .range-component .MuiSelect-icon {
    font-size: 22px !important; }
  .range-component .MuiInput-underline::before {
    border-bottom-style: solid !important; }
  .range-component.Mui-disabled .MuiInput-underline.Mui-disabled:before,
  .range-component.Mui-disabled .MuiInput-underline::before {
    border-bottom-style: solid !important;
    background-color: #ebebeb;
    border-bottom: 1px #d4d4d4 solid; }
  .range-component .MuiFormControl-root {
    min-height: unset !important; }

.circle-wrap {
  margin: 0 auto 10px;
  width: 50px;
  height: 50px;
  background: #d4e1f7;
  border-radius: 50%; }

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%; }

.circle-wrap .circle .mask {
  clip: rect(0px, 50px, 50px, 25px); }

.circle-wrap .circle .mask .fill {
  clip: rect(0px, 25px, 50px, 0px);
  background-color: var(--palette-primary-main); }

.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
  animation: fill1 ease-in-out 3s;
  transform: rotate(45deg); }

.circle-wrap-2 .circle .mask.full,
.circle-wrap-2 .circle .fill {
  animation: fill2 ease-in-out 3s;
  transform: rotate(90deg); }

.circle-wrap-3 .circle .mask.full,
.circle-wrap-3 .circle .fill {
  animation: fill3 ease-in-out 3s;
  transform: rotate(135deg); }

.circle-wrap-4 .circle .mask.full,
.circle-wrap-4 .circle .fill {
  animation: fill4 ease-in-out 3s;
  transform: rotate(180deg); }

@keyframes fill1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(45deg); } }

@keyframes fill2 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(90deg); } }

@keyframes fill3 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(135deg); } }

@keyframes fill4 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(180deg); } }

.circle-wrap .inside-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--palette-light);
  line-height: 40px;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  z-index: 1;
  font-weight: 700;
  font-size: 16px; }

.stepper-register-wrapper {
  position: relative;
  padding: 0 0 8px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between; }
  @media screen and (min-device-width: 490px) {
    .stepper-register-wrapper .stepper-register-next {
      text-align: right;
      margin-right: 0;
      order: 3;
      margin-top: 16px;
      width: 130px;
      min-width: 130px; }
    .stepper-register-wrapper .stepper-register-step {
      order: 2; }
    .stepper-register-wrapper .stepper-register-back {
      text-align: left;
      margin-left: 0;
      order: 1;
      margin-top: 16px;
      width: 130px;
      min-width: 130px; } }
  @media screen and (max-device-width: 490px) {
    .stepper-register-wrapper {
      flex-wrap: wrap; } }
  .stepper-register-wrapper .stepper-register-title {
    opacity: 0.7;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2.08px;
    margin-bottom: 3px;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%; }

.stepper-register-step {
  margin-top: 11px; }

@media only screen and (max-device-width: 490px) {
  .stepper-register-step,
  .stepper-register-back,
  .stepper-register-next {
    text-align: center;
    width: 100%; }
  .stepper-register-wrapper .stepper-register-title {
    position: relative;
    bottom: auto;
    left: auto; } }

@media only screen and (max-device-width: 600px) {
  .stepper-register-back,
  .stepper-register-next {
    text-align: center; }
  .stepper-register-next {
    margin-bottom: 8px; }
  .stepper-register-back {
    margin-top: 16px; }
  .stepper-register-wrapper .stepper-register-title {
    position: relative;
    bottom: auto;
    left: auto; } }

.stepper-register-only-button {
  width: 100%; }

.stepper-register-wrapper-fullwidth {
  flex-wrap: wrap;
  justify-content: center; }

.styled-nav::-webkit-scrollbar-track,
.MuiDialogContent-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(109, 109, 109, 0.5); }

.styled-nav::-webkit-scrollbar,
.MuiDialogContent-root::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: rgba(1, 16, 32, 0.7); }

.styled-nav::-webkit-scrollbar-thumb,
.MuiDialogContent-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(245, 245, 245, 0.5); }

.render-questions-list > div:first-child .questionWrapper {
  margin-top: 32px; }

.surveyDescription {
  width: calc(100% + 20px) !important;
  padding: 10px;
  margin: 0 -10px;
  background-color: var(--palette-primary-A200); }

.surveyBoxContainer .surveyDescription {
  width: 100% !important;
  margin: 0 auto; }
  @media only screen and (max-width: 960px) {
    .surveyBoxContainer .surveyDescription {
      width: 100% !important; } }

.questionWrapper .Mui-error {
  color: #D81C10 !important;
  font-weight: 500;
  letter-spacing: 0.1px; }
  .questionWrapper .Mui-error.MuiInputBase-multiline {
    font-size: 16px !important; }

.errorWrapper,
.questionWrapper .MuiFormHelperText-root.Mui-error {
  background: transparent !important;
  color: var(--palette-error-main) !important;
  color: #D81C10 !important;
  padding: 2px 0 !important;
  min-height: 20px;
  font-weight: 500;
  font-size: 12px !important;
  display: block;
  letter-spacing: 0.1px;
  margin: 0; }
  .errorWrapper:before,
  .questionWrapper .MuiFormHelperText-root.Mui-error:before {
    content: "";
    width: 5000px;
    height: 15000px;
    background: #ffecec;
    display: block;
    position: absolute;
    top: -2500px;
    left: -2500px;
    z-index: -1; }

.questionWrapper {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 10px;
  border: 2px var(--palette-primary-A100) solid; }
  @media only screen and (max-width: 960px) {
    .questionWrapper {
      width: 100%;
      padding: 10px; } }
  .questionWrapper .MuiFormControl-root {
    position: initial !important; }
  .questionWrapper.questionWrapperDropdown {
    z-index: 2; }
    .questionWrapper.questionWrapperDropdown.questionWrapper-error {
      background: #ffecec;
      z-index: 2; }
      .questionWrapper.questionWrapperDropdown.questionWrapper-error .errorWrapper:before,
      .questionWrapper.questionWrapperDropdown.questionWrapper-error .MuiFormHelperText-root.Mui-error:before {
        display: none; }

.zoom-overlay-open .mainBlock,
.zoom-overlay-open .menuButton {
  z-index: auto !important; }

.zoom-img,
.zoom-img-wrap {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 auto !important; }

img.zoom-img {
  border-radius: inherit !important; }

.zoom-overlay-open div:not(.zoom-img-wrap) {
  pointer-events: none; }

.status-cell svg {
  font-size: 32px;
  width: 32px;
  height: 32px; }

.admin-fullheight-wrapper {
  padding: 3% 7% 30px;
  height: calc(100vh - 64px); }

.admin-table-fullheight {
  height: calc(90vh - 312px);
  min-height: 300px;
  margin: 0 -32px; }
  .admin-table-fullheight > table {
    width: 100% !important;
    margin: 0 !important; }
    .admin-table-fullheight > table thead tr th:last-child,
    .admin-table-fullheight > table tbody tr td:last-child {
      padding-right: 15px !important; }
  .admin-table-fullheight .MuiTableCell-root {
    padding-top: 8px !important;
    padding-right: 8px !important;
    padding-bottom: 8px !important; }
  .admin-table-fullheight .MuiTableCell-stickyHeader {
    border-bottom: 1px solid #e0e0e0 !important; }

.admin-table-fullheight.admin-table-fullheight-long {
  height: calc(90vh - 99px); }

.admin-table-progress {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center; }

.white-background .form-control {
  margin-left: 0 !important; }

.white-background div, .white-background p, .white-background span:not(.required-question-mark), .white-background label {
  color: var(--palette-text-primary) !important; }

.white-background .MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary,
.white-background .MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
  color: var(--palette-text-primary) !important; }

.white-background .MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary svg {
  border-color: var(--palette-text-primary) !important; }

.white-background .Mui-disabled {
  color: var(--palette-text-disabled) !important;
  border-color: var(--palette-text-disabled) !important; }
  .white-background .Mui-disabled * {
    color: var(--palette-text-disabled) !important;
    border-color: var(--palette-text-disabled) !important; }
  .white-background .Mui-disabled + .MuiFormControlLabel-label {
    color: var(--palette-text-disabled) !important; }
    .white-background .Mui-disabled + .MuiFormControlLabel-label * {
      color: var(--palette-text-disabled) !important; }
  .white-background .Mui-disabled.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary svg {
    border-color: var(--palette-text-disabled) !important; }

.white-background .admin-question-description *,
.white-background .admin-question-description div, .white-background .admin-question-description p, .white-background .admin-question-description span:not(.required-question-mark), .white-background .admin-question-description label,
.white-background .admin-question-description .MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary,
.white-background .admin-question-description .MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary + .MuiFormControlLabel-label {
  color: var(--palette-secondary-main) !important; }

.bilateral-table {
  border-left: 2px var(--palette-primary-A100) solid;
  border-right: 2px var(--palette-primary-A100) solid; }
  @media only screen and (max-width: 767px) {
    .bilateral-table .MuiGrid-grid-xs-6 {
      padding-bottom: 10px !important; } }

@media only screen and (max-width: 768px) {
  .bilateral-table-head-wrap {
    border-bottom: 2px solid #ededf1; } }

.admin .bilateral-table ~ div:last-of-type {
  padding-bottom: 10px; }

.bilateral-container {
  border-left: 2px var(--palette-primary-A100) solid;
  border-right: 2px var(--palette-primary-A100) solid; }
  .bilateral-container .bilateral-container-title {
    padding: 10px; }
  .bilateral-container .MuiGrid-grid-xs-8 {
    display: flex; }
  .bilateral-container .bilateral-container-content.bilateral-grouped .bilateral-grid.MuiGrid-grid-xs-6:first-child {
    max-width: 66.666667%;
    flex-basis: 66.666667%; }
    .bilateral-container .bilateral-container-content.bilateral-grouped .bilateral-grid.MuiGrid-grid-xs-6:first-child .errorWrapper {
      margin-left: calc(50% + 21px); }
      .bilateral-container .bilateral-container-content.bilateral-grouped .bilateral-grid.MuiGrid-grid-xs-6:first-child .errorWrapper::before {
        left: calc(50% + 10px); }
    .bilateral-container .bilateral-container-content.bilateral-grouped .bilateral-grid.MuiGrid-grid-xs-6:first-child .button-remove-answer-wrapper {
      margin-left: auto; }
  .bilateral-container .bilateral-container-content.bilateral-grouped .bilateral-grid.MuiGrid-grid-xs-6:last-child {
    max-width: 33.333333%;
    flex-basis: 33.333333%; }
    @media screen and (max-width: 767px) {
      .bilateral-container .bilateral-container-content.bilateral-grouped .bilateral-grid.MuiGrid-grid-xs-6:last-child {
        border-left: none !important; } }
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range-header > .MuiGrid-grid-xs-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-6,
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range > .MuiGrid-grid-xs-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-6 {
    max-width: 100%;
    flex-basis: 100%;
    padding-right: 0; }
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range-header > .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6 + .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6,
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range-header > .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6 + .MuiGrid-grid-xs-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-6,
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range > .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6 + .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6,
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range > .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6 + .MuiGrid-grid-xs-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-6 {
    max-width: 50%;
    flex-basis: 50%;
    padding-left: 12px; }
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range-header .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6,
  .bilateral-container .bilateral-container-content.bilateral-grouped .container-questions-range .MuiGrid-grid-xs-12.MuiGrid-grid-md-4.MuiGrid-grid-lg-6 {
    max-width: 50%;
    flex-basis: 50%; }

.bilateral-grid .questionWrapper {
  border: none;
  margin: 0; }

.bilateral-grid .MuiGrid-root.MuiGrid-grid-xs-6:empty {
  display: none; }
  .bilateral-grid .MuiGrid-root.MuiGrid-grid-xs-6:empty + .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 {
    max-width: 100%;
    flex-basis: 100%; }
    .bilateral-grid .MuiGrid-root.MuiGrid-grid-xs-6:empty + .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 > div {
      width: auto; }
    .bilateral-grid .MuiGrid-root.MuiGrid-grid-xs-6:empty + .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 .radio-group-header-text {
      width: calc(50% - 8px);
      margin-left: auto; }
    .bilateral-grid .MuiGrid-root.MuiGrid-grid-xs-6:empty + .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 .container-questions-range-header,
    .bilateral-grid .MuiGrid-root.MuiGrid-grid-xs-6:empty + .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 .container-questions-range {
      padding-left: 50%; }

.bilateral-grid .select-component,
.bilateral-grid .dropdown-component {
  min-width: initial !important;
  margin-left: 0 !important; }

.bilateral-grid .range-component .MuiGrid-grid-sm-4 {
  max-width: 100%;
  flex-basis: 100%; }

.bilateral-grid .range-component .MuiGrid-grid-sm-2 {
  max-width: 50%;
  flex-basis: 50%;
  display: flex;
  padding-left: 22px; }
  .bilateral-grid .range-component .MuiGrid-grid-sm-2 > div {
    width: 100%; }

.bilateral-grid .range-component .range-component-label {
  max-width: 50%;
  flex-basis: 50%; }

.bilateral-grid .range-component .MuiFormControl-fullWidth {
  width: 100%;
  min-height: initial !important; }

.bilateral-grid .container-questions-range-header {
  padding-bottom: 0; }

.bilateral-grid .container-questions-range {
  padding-bottom: 0;
  padding-top: 10px; }
  .bilateral-grid .container-questions-range .MuiTypography-subtitle2 {
    margin-bottom: 0; }

.bilateral-grid .MuiTypography-subtitle1,
.bilateral-grid .MuiTypography-subtitle2,
.bilateral-grid .MuiTypography-body2,
.bilateral-grid .MuiTypography-body1,
.bilateral-grid .MuiFormControlLabel-root {
  font-size: 14px !important;
  margin-right: 0;
  word-break: break-word; }

.bilateral-grid .MuiGrid-grid-xs-3 {
  word-break: break-all;
  max-width: 50%;
  flex-basis: 50%;
  padding-left: 12px; }

.bilateral-grid .container-radiogroup-grid > .MuiFormGroup-root > .MuiFormGroup-root > .MuiFormControlLabel-root,
.bilateral-grid .container-radiogroup-grid > .MuiFormGroup-root > .MuiFormGroup-root > .MuiGrid-root {
  border: none;
  padding: 0 !important; }

.bilateral-grid .questionWrapper > div,
.bilateral-grid .questionWrapper .MuiFormControl-root {
  margin-top: 0 !important; }

@media screen and (max-width: 767px) {
  .bilateral-grid .questionWrapper {
    padding-right: 4px; } }

.bilateral-grid .dropzone-file {
  margin-top: 0; }
  .bilateral-grid .dropzone-file .MuiButton-containedSizeLarge {
    padding: 10px; }

.bilateral-grid .global-input-wrapper {
  min-height: unset; }

.bilateral-grid .radiogroup-item {
  margin-bottom: 5px !important; }
  .bilateral-grid .radiogroup-item .MuiGrid-grid-sm-6:last-child {
    padding-left: 12px; }

.bilateral-grid .container-questions-grid {
  flex-direction: column; }

.bilateral-grid .MuiGrid-container .MuiGrid-grid-sm-6:first-child {
  padding-right: 10px; }

.bilateral-grid .MuiGrid-container .MuiGrid-grid-sm-6:last-child {
  padding-left: 10px; }

.bilateral-grid .bilateral-radiogroup-header-text.MuiGrid-container .MuiGrid-grid-xs-6:first-child,
.bilateral-grid .bilateral-radiogroup-header-text.MuiGrid-container .MuiGrid-grid-sm-6:first-child {
  padding-right: 10px;
  max-width: 50%;
  flex-basis: 50%; }

.bilateral-grid .bilateral-radiogroup-header-text.MuiGrid-container .MuiGrid-grid-xs-6:last-child
.MuiGrid-grid-sm-6:last-child {
  padding-left: 10px;
  max-width: 50%;
  flex-basis: 50%; }

.bilateral-grid .bilateral-radiogroup-header-text.MuiGrid-container .MuiGrid-item {
  justify-content: center; }

@media screen and (max-width: 767px) {
  .bilateral-container .bilateral-container-title,
  .bilateral-container .bilateral-container-content-outer {
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .bilateral-container .bilateral-container-content-outer .MuiGrid-grid-xs-6 {
    border-left: none !important; } }

.bilateral-survey {
  margin-bottom: 0 !important;
  border-top: 2px var(--palette-primary-A100) solid;
  border-bottom: 2px var(--palette-primary-A100) solid; }
  .bilateral-survey + .bilateral-survey {
    border-top: none; }
  .bilateral-survey + .standart-survey .questionWrapper {
    border-top: none; }
  .bilateral-survey .question-container-children > div:last-child:not(:empty) > div {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important; }
  .bilateral-survey .question-container-children > div:last-child:empty {
    margin-bottom: -10px !important; }
  @media only screen and (max-width: 767px) {
    .bilateral-survey .MuiGrid-spacing-xs-2 {
      margin: 0 !important; } }
  .bilateral-survey .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 0 8px 8px 8px !important; }
    @media only screen and (max-width: 767px) {
      .bilateral-survey .MuiGrid-spacing-xs-2 > .MuiGrid-item {
        padding: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .bilateral-survey .MuiSlider-markLabel {
      font-size: 8px !important; } }
  @media only screen and (max-width: 767px) {
    .bilateral-survey .MuiButton-label {
      white-space: normal; } }
  @media only screen and (max-width: 767px) {
    .bilateral-survey .MuiButton-root {
      min-width: unset; } }
  @media only screen and (max-width: 767px) {
    .bilateral-survey .button-remove-answer.MuiButton-root {
      letter-spacing: 0;
      font-size: 10px;
      padding: 0 5px !important;
      margin: 10px 0 10px auto !important; } }

.lazyload-wrapper + .lazyload-wrapper .bilateral-survey {
  border-top: none; }

.lazyload-wrapper + .lazyload-wrapper .standart-survey .questionWrapper {
  border-top: none; }

.standart-survey + .standart-survey,
.standart-survey + .bilateral-survey {
  margin-top: -2px !important; }

.bilateral-survey .button-remove-answer-wrapper {
  width: calc(50% - 12px);
  flex-basis: calc(50% - 12px); }
  .bilateral-survey .button-remove-answer-wrapper .button-remove-answer {
    margin: 10px 0 !important;
    padding: 3px 12px !important;
    height: auto !important;
    letter-spacing: 0.5px !important; }

.uitype-1 .MuiFormHelperText-contained {
  background-color: #fff !important; }

.uitype-1 .select-selector .MuiFormControl-root {
  background-color: #E6E6E6 !important;
  color: #000 !important; }
  .uitype-1 .select-selector .MuiFormControl-root .MuiSelect-icon {
    color: #2d2d2d !important; }
  .uitype-1 .select-selector .MuiFormControl-root.Mui-error {
    color: #f1493e !important; }

.uitype-1 .MuiFormControl-root .MuiInputLabel-root {
  color: #000 !important; }
  .uitype-1 .MuiFormControl-root .MuiInputLabel-root.Mui-disabled {
    opacity: 0.4; }
  .uitype-1 .MuiFormControl-root .MuiInputLabel-root.Mui-error {
    color: #f1493e !important; }

.uitype-1 .MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorSecondary.MuiFilledInput-colorSecondary {
  background-color: #E6E6E6 !important;
  color: #000 !important; }
  .uitype-1 .MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorSecondary.MuiFilledInput-colorSecondary.Mui-disabled {
    background: rgba(0, 0, 0, 0.12) !important; }
  .uitype-1 .MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorSecondary.MuiFilledInput-colorSecondary .MuiIconButton-root {
    color: #a4a4a4 !important; }

.uitype-1 .MuiFormControl-root .MuiSelect-filled.MuiSelect-filled {
  color: #000 !important;
  background-color: #E6E6E6 !important; }

.uitype-1 .MuiFormControlLabel-root,
.uitype-1 .MuiFormLabel-root.MuiFormLabel-colorSecondary.Mui-focused.MuiInputLabel-filled,
.uitype-1 .MuiFormLabel-colorSecondary.Mui-focused {
  color: #000 !important; }

.uitype-1 .MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary:not(.Mui-checked) svg {
  border-color: #0067d8 !important; }

.uitype-1 .MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorSecondary.Mui-checked svg {
  fill: #0067d8 !important; }

.uitype-1 .MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: transparent !important; }

@media print {
  [data-print="none"] {
    display: none !important; }
  html {
    margin: 0;
    padding: 0;
    box-shadow: 0;
    background: transparent !important;
    height: auto;
    min-height: auto;
    display: block;
    page-break-after: avoid;
    page-break-before: avoid;
    overflow: auto !important; }
  body {
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    height: unset !important;
    min-height: unset !important;
    display: block;
    page-break-after: avoid;
    page-break-before: avoid;
    overflow: visible !important;
    min-width: 767px; }
  .no-print,
  .left-navigation,
  .MuiPopover-root {
    display: none !important; }
  .mainBlock {
    min-height: unset !important;
    max-height: unset !important;
    overflow-x: initial !important;
    flex-grow: unset !important;
    background: transparent !important; }
  .main-content {
    overflow-x: initial !important;
    min-width: unset !important;
    background: transparent !important;
    display: block !important;
    min-height: unset !important;
    max-height: unset !important;
    flex-grow: unset !important; }
  button:not(.MuiIconButton-root):not(.button-icon) {
    display: none; }
  .case-alert-table-wrapper,
  .task-info-inner-table,
  .alert-table-inner {
    max-height: unset !important; }
  .detail-header {
    background: transparent !important;
    border: 1px #bbb solid; }
    .detail-header *:not(.MuiIconButton-label):not(svg):not(path) {
      background: transparent !important; }
    .detail-header * {
      color: #000 !important; }
  .page-wrapper {
    padding-top: 0 !important; }
  .composite-label-paper-content-wrapper,
  .composite-label-paper-content,
  .component-label-paper-content-wrapper,
  .component-label-paper-content {
    overflow: visible !important;
    height: auto !important; }
  .component-label-paper-content {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .label-paper-inner {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important; }
  .component-label-paper {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    transform: none !important; }
  .component-label-paper,
  .component-label-paper-title,
  .component-label-small,
  .component-label-k-number,
  .component-label-number,
  .observational-protocol-menu-inner {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: avoid;
    break-after: avoid;
    position: relative;
    min-height: 50px; }
  .fileUploaderWrapper,
  .dropzone-file {
    display: block !important;
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: avoid;
    break-after: avoid;
    position: relative;
    text-align: center; }
  .wide-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: block !important; }
  div[class*="MuiPaper-elevation"] {
    box-shadow: none !important;
    border: none !important;
    min-height: 0 !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-12,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-12,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-12,
  .MuiGrid-grid-sm-12,
  .MuiGrid-grid-md-12,
  .MuiGrid-grid-lg-12 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-11,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-11,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-11,
  .MuiGrid-grid-sm-11,
  .MuiGrid-grid-md-11,
  .MuiGrid-grid-lg-11 {
    flex-grow: 0 !important;
    max-width: 91.666667% !important;
    flex-basis: 91.666667% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-10,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-10,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-10,
  .MuiGrid-grid-sm-10,
  .MuiGrid-grid-md-10,
  .MuiGrid-grid-lg-10 {
    flex-grow: 0 !important;
    max-width: 83.333333% !important;
    flex-basis: 83.333333% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-9,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-9,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-9,
  .MuiGrid-grid-sm-9,
  .MuiGrid-grid-md-9,
  .MuiGrid-grid-lg-9 {
    flex-grow: 0 !important;
    max-width: 75% !important;
    flex-basis: 75% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-8,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-8,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-8,
  .MuiGrid-grid-sm-8,
  .MuiGrid-grid-md-8,
  .MuiGrid-grid-lg-8 {
    flex-grow: 0 !important;
    max-width: 66.666667% !important;
    flex-basis: 66.666667% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-7,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-7,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-7,
  .MuiGrid-grid-sm-7,
  .MuiGrid-grid-md-7,
  .MuiGrid-grid-lg-7 {
    flex-grow: 0 !important;
    max-width: 58.333333% !important;
    flex-basis: 58.333333% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-6,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-6,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-6,
  .MuiGrid-grid-sm-6,
  .MuiGrid-grid-md-6,
  .MuiGrid-grid-lg-6 {
    flex-grow: 0 !important;
    max-width: 50% !important;
    flex-basis: 50% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-5,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-5,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-5,
  .MuiGrid-grid-sm-5,
  .MuiGrid-grid-md-5,
  .MuiGrid-grid-lg-5 {
    flex-grow: 0 !important;
    max-width: 41.666667% !important;
    flex-basis: 41.666667% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-4,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-4,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-4,
  .MuiGrid-grid-sm-4,
  .MuiGrid-grid-md-4,
  .MuiGrid-grid-lg-4 {
    flex-grow: 0 !important;
    max-width: 33.333333% !important;
    flex-basis: 33.333333% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-3,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-3,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-3,
  .MuiGrid-grid-sm-3,
  .MuiGrid-grid-md-3,
  .MuiGrid-grid-lg-3 {
    flex-grow: 0 !important;
    max-width: 25% !important;
    flex-basis: 25% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-2,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-2,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-2,
  .MuiGrid-grid-sm-2,
  .MuiGrid-grid-md-2,
  .MuiGrid-grid-lg-2 {
    flex-grow: 0 !important;
    max-width: 16.666667% !important;
    flex-basis: 16.666667% !important; }
  .MuiGrid-grid-xs-12 .MuiGrid-grid-sm-1,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-md-1,
  .MuiGrid-grid-xs-12 .MuiGrid-grid-lg-1,
  .MuiGrid-grid-sm-1,
  .MuiGrid-grid-md-1,
  .MuiGrid-grid-lg-1 {
    flex-grow: 0 !important;
    max-width: 8.333333% !important;
    flex-basis: 8.333333% !important; }
  .page-case-detail h2 {
    font-size: 24px; }
  .header-case-detail {
    flex-wrap: nowrap; }
  .surveyDescription {
    padding-top: 20px; }
  .component-label-paper-content-wrapper,
  .component-label-paper-content {
    border: 1px transparent solid; }
  .wide-container,
  .print-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .print-wrapper {
    padding-top: 0 !important; }
  .page-topbar {
    display: none !important; }
  table th {
    padding: 16px 0; }
    table th .MuiTypography-caption {
      min-width: 100px; }
  table td {
    vertical-align: middle;
    min-width: unset !important; }
    table td a {
      display: inline-block !important; }
    table td .MuiGrid-item {
      display: inline-flex !important;
      margin: auto 0 !important; }
    table td.MuiTableCell-sizeSmall {
      padding: 6px; }
  .print-block-visible {
    max-height: unset !important;
    overflow: visible !important; }
  .report-container {
    display: flex !important;
    flex-direction: column-reverse; }
  .report-container-navigator {
    display: block !important; }
  .report-container-report {
    display: block !important;
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .infoAlert.infoAlertInPaper {
    page-break-inside: avoid;
    break-inside: avoid; }
  .MuiDialog-root:after {
    content: "";
    background: #fff;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1; }
  .MuiDialog-root .MuiBox-root *:not(.flag):not([class^="MuiSwitch"]),
  .MuiDialog-root .MuiGrid-container *:not(.flag):not([class^="MuiSwitch"]) {
    background: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
    -webkit-text-fill-color: #000 !important; }
  .MuiDialog-root .MuiBox-root .react-tel-input,
  .MuiDialog-root .MuiGrid-container .react-tel-input {
    border-bottom: 1px #011020 solid !important; }
  .MuiDialog-root .MuiBox-root svg,
  .MuiDialog-root .MuiGrid-container svg {
    fill: #000 !important; }
  .MuiDialog-root .MuiBox-root .MuiTouchRipple-root,
  .MuiDialog-root .MuiGrid-container .MuiTouchRipple-root {
    display: none !important; }
  .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"].MuiSwitch-track,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"].MuiSwitch-track {
    background: #fff !important;
    border: 1px #aaa solid;
    opacity: 1; }
  .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"].MuiSwitch-thumb,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"].MuiSwitch-thumb {
    border: 1px #aaa solid;
    background: #fff !important; }
  .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"] .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"] .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: #ddd !important; }
  .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"] .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"] .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background: #222 !important;
    border: 1px #222 solid; }
  .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"].MuiSwitch-input, .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"].MuiSwitch-colorPrimary.Mui-checked,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"].MuiSwitch-input,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"].MuiSwitch-colorPrimary.Mui-checked {
    color: #000 !important; }
  .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"].MuiButtonBase-root.Mui-checked .MuiSwitch-thumb,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"].MuiButtonBase-root.Mui-checked .MuiSwitch-thumb {
    background: #000; }
  .MuiDialog-root .MuiBox-root *[class^="MuiSwitch"].MuiSwitch-switchBase,
  .MuiDialog-root .MuiGrid-container *[class^="MuiSwitch"].MuiSwitch-switchBase {
    background: transparent !important; }
  .MuiDialog-root .MuiDialogContent-root > form > .MuiGrid-container,
  .MuiDialog-root .MuiDialogContent-root > .MuiGrid-container {
    background: #fff !important; }
  .MuiDialog-root .MuiSwitch-switchBase {
    padding: 0 !important;
    margin: 9px !important; }
  .MuiDialog-root .MuiInputLabel-filled {
    transform: translate(0, 20px) scale(1); }
  .MuiDialog-root .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0, 10px) scale(0.75); }
  .MuiDialog-root .MuiFilledInput-input {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  body.modal-open {
    position: relative; }
    body.modal-open #root {
      display: none !important; }
    body.modal-open:after {
      content: "";
      background: #fff;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      overflow: hidden; }
    body.modal-open .MuiBackdrop-root {
      display: none !important; }
  .dashboard-background {
    background: #fff !important; }
  .personal-plan-title {
    color: #000 !important; }
  .case-phi-wrapper {
    align-self: stretch;
    display: flex;
    align-items: center; }
  .case-phi {
    height: 100%; }
  .case-phi:not(.MuiIconButton-label):not(svg):not(path) {
    background-color: #eee !important; }
    .case-phi:not(.MuiIconButton-label):not(svg):not(path) * {
      background-color: #eee !important; }
  .container-questions-range .MuiFormGroup-root {
    flex-wrap: nowrap; }
  .task-header-section-phi {
    flex-grow: 0 !important;
    max-width: 50% !important;
    flex-basis: 50% !important; }
  .task-header-section-header {
    flex-grow: 0 !important;
    max-width: 50% !important;
    flex-basis: 50% !important;
    align-self: stretch;
    display: flex;
    align-items: center; }
  .header-case-detail .MuiGrid-item,
  .header-task-detail .MuiGrid-item {
    padding: 8px !important; }
  .case-header-section-inner {
    align-self: stretch;
    display: flex;
    align-items: center; }
  .task-phi-button-show-wrapper button {
    padding: 0; }
  .task-phi-name {
    font-size: 23px !important;
    font-weight: bold !important; }
  .MuiDialog-root .MuiBox-root .selector-chip-wrapper,
  .MuiDialog-root .MuiGrid-container .selector-chip-wrapper,
  .selector-chip-wrapper {
    border: 1px #ccc solid !important;
    padding: 0 !important;
    margin: 2px 4px !important;
    border-radius: 20px; }
    .MuiDialog-root .MuiBox-root .selector-chip-wrapper svg.MuiChip-deleteIcon,
    .MuiDialog-root .MuiGrid-container .selector-chip-wrapper svg.MuiChip-deleteIcon,
    .selector-chip-wrapper svg.MuiChip-deleteIcon {
      fill: #ccc !important; }
  .status-cell {
    margin-right: 8px; }
  .mainPatientWrapper {
    min-height: unset !important;
    max-height: unset !important;
    overflow-x: initial !important;
    flex-grow: unset !important;
    background: transparent !important;
    display: block !important;
    padding: 0 !important; }
  .mainHeaderMenu {
    display: none; }
  .survey-index {
    background: transparent !important;
    min-height: unset !important; }
  .survey-form-page {
    margin: 0 auto !important;
    padding: 0 !important; }
  .survey-form-wrapper {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-top: 60px; }
  .mainHeaderOuter,
  .mainHeaderInner {
    position: relative !important; }
  .surveyDescription {
    background: transparent !important;
    color: #000 !important;
    border-left: 8px #11121c solid; }
  .dashboard-wrapper {
    flex-direction: row !important; }
  .patient-updates-item {
    flex-wrap: nowrap !important; }
  #root {
    min-height: unset !important; }
  .patient-main-content-inner {
    height: unset !important; }
  .patient-images-main,
  .rootChart {
    background: transparent !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important; }
    .patient-images-main *,
    .rootChart * {
      color: #000 !important; }
    .patient-images-main .boxDueLabel *,
    .patient-images-main .boxScoreLabel *,
    .patient-images-main .survey-response-results-new-score,
    .rootChart .boxDueLabel *,
    .rootChart .boxScoreLabel *,
    .rootChart .survey-response-results-new-score {
      color: #fff !important; }
  .patient-case-details-wrapper .patient-treatment-img-wrapper,
  .patient-case-details-wrapper .patient-treatment-desc-wrapper,
  .patient-case-details-wrapper .patient-pathology-img-wrapper,
  .patient-case-details-wrapper .patient-pathology-desc-wrapper {
    flex-grow: 0 !important;
    max-width: unset !important;
    flex-basis: unset !important; }
  .patient-case-details-wrapper .patient-treatment-img-wrapper {
    order: 1;
    max-width: 700px !important;
    max-height: 1200px;
    margin: auto;
    page-break-inside: avoid;
    break-inside: avoid; }
  .patient-case-details-wrapper .patient-treatment-desc-wrapper {
    order: 2; }
  .patient-case-details-wrapper .patient-pathology-desc-wrapper {
    order: 4; }
  .patient-case-details-wrapper .patient-pathology-img-wrapper {
    order: 3;
    max-width: 700px !important;
    max-height: 1200px;
    margin: auto;
    page-break-inside: avoid;
    break-inside: avoid; }
  .image-item-wrapper,
  .rootChart,
  .chartGraph,
  .patient-bar-chart,
  .patient-bar-chart svg,
  .patient-bar-chart svg g {
    display: block !important;
    position: relative;
    page-break-inside: avoid;
    break-inside: avoid; }
  .full-albom-wrapper,
  .patient-images-root {
    display: block !important; }
  .chartGraph {
    overflow: visible !important; }
  .patient-bar-chart {
    padding-left: 20px !important; }
  .question-container-title span:first-child {
    display: inline-block !important;
    position: relative;
    page-break-inside: avoid;
    break-inside: avoid; }
  .question-container-title span:last-child {
    display: inline !important; }
  .questionWrapper {
    display: inline-block !important;
    position: relative;
    page-break-inside: avoid;
    break-inside: avoid; } }

.error-page {
  background-image: url("../assets/shared/error/arrows-light.png"); }

.splash-screen {
  background-image: url("../assets/shared/splash-screen/arrows-bright-compressed.jpg"); }
  .splash-screen__logo {
    background-image: url("../assets/shared/logo-wordmark-white.svg"); }
