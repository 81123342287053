.birthDateClass {
    label + .MuiInput-formControl {
        margin-top: 0;
        padding-top: 27px;
    }
    .MuiInputBase-input.MuiInput-input {
        margin: 0;
        padding: 0 0 10px 0;
    }
    .MuiInputBase-formControl {
        color: white;
        position: relative;
        font-size: 16px;
        transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        background-color: rgba(0, 0, 0, 0.09);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .MuiInputBase-formControl:hover {
        background-color: rgba(0, 0, 0, 0.13);
    }
    .MuiInput-underline:after {
        border-bottom-width: 1px !important;
    }
    .MuiFilledInput-underline:hover:before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
    }
}
