.select-component {
	min-width: 280px !important;
	width: 100%;
	.MuiFormControlLabel-root {
		margin-right: 0;
	}
}

.dropdown-component {
	min-width: 280px !important;
	width: 100%;
	.MuiFormControlLabel-root {
		margin-right: 0;
	}
}

// React select styled
.react-select-styled__input-container {
	display: inline-flex !important;
}
.react-select-styled__control {
	border-radius: 5px 5px 0 0;
	background-color: #ebebeb;
	border: none;
	border-bottom: 1px #d2d2d2 solid;
	box-shadow: none !important;
}
.react-select-styled__placeholder {
	color: #4a4a4a !important;
}
.react-select-styled__indicator-separator {
	display: none;
}
.react-select-styled__dropdown-indicator {
	svg {
		display: none;
	}
	position: relative;
	&::after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #000;
		margin-top: 2px;
	}
}

.react-select-styled__menu-portal {
	z-index: 99999;
	.react-select-styled__menu {
		margin: 0 !important;
	}
	.react-select-styled__option {
		background: #fff !important;
		color: #4a4a4a !important;
		font-size: 17px;
		&:hover,
		&:active,
		&:focus {
			background: #ededed !important;
			color: #4a4a4a !important;
		}
	}
}

.react-select-styled--is-disabled {
	.react-select-styled__control--is-disabled {
		background-color: #ebebeb;
		border-bottom: 1px #d4d4d4 solid;
	}
	.react-select-styled__placeholder {
		color: #bababa !important;
	}
	.react-select-styled__dropdown-indicator {
		&::after {
			border-top: 5px solid #a4a4a4;
		}
	}
}

.range-component {
	padding-bottom: 10px;
	.MuiTypography-subtitle1 {
		font-size: 17px;
		letter-spacing: 1px;
		font-weight: 400;
		padding: 8px 8px 0 0;
	}
	.MuiInputBase-root {
		width: 100%;
	}
	.MuiSelect-select {
		border-radius: 5px 5px 0 0;
		background-color: #ebebeb;
		border: none;
		border-bottom: 1px #d2d2d2 solid;
		box-shadow: none !important;
	}
	.MuiSelect-icon {
		font-size: 22px !important;
	}
	.MuiInput-underline::before {
		border-bottom-style: solid !important ;
	}

	&.Mui-disabled {
		.MuiInput-underline.Mui-disabled:before,
		.MuiInput-underline::before {
			border-bottom-style: solid !important ;
			background-color: #ebebeb;
			border-bottom: 1px #d4d4d4 solid;
		}
	}

	.MuiFormControl-root {
		min-height: unset !important;
	}
}
