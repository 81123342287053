.tabs-as-chips {
	@media only screen and (max-device-width:640px) {
		width: 126%;
		margin: 0 -13%;
	}
	@media only screen and (max-width:640px) {
		width: 126%;
		margin: 0 -13%;
	}

	button.MuiTab-root {
		color: var(--palette-text-primary);
		border: 1px solid rgba(0, 0, 0, 0.42);
		padding: 8px 0;
		font-size: 15px;
		border-radius: 3px;
		background-color: #fff;
		min-width: 100px !important;
		min-height: 0 !important;
		@media only screen and (min-device-width:640px) {
			max-width: 100%;
		}
		@media only screen and (min-width:640px) {
			max-width: 100%;
		}
		
		margin: 8px 16px 8px 0;
		line-height: 1 !important;
		.MuiTab-wrapper {
			display: block;
		}
		.tabs-as-chips-label {
			font-size: 15px;
			color: #000;
			overflow: hidden;
			white-space: nowrap;
			padding-left: 12px;
			padding-right: 12px;
			text-overflow: ellipsis;
			line-height: 1 !important;
			@media only screen and (max-device-width:640px) {
				font-size: 12px;
			}
			@media only screen and (max-width:640px) {
				font-size: 12px;
			}
		}
		&.Mui-selected {
			border-color: #e0e0e0;
			background-color: #e0e0e0;
			padding-left: 20px;
				position: relative;
				&::before {
					content: "";
					display: block;
					position: absolute;
					top: calc(50% - 1px);
					left: 16px;
					transform: translateY(-50%) rotate(45deg);
					height: 10px;
					width: 4px;
					border-bottom: 1px solid #000;
					border-right: 1px solid #000;
				}
		}
	}
	.MuiTabs-indicator {
		display: none !important;
	}
	.MuiTab-textColorInherit {
		opacity: 1;
	}
	.MuiSvgIcon-fontSizeSmall {
		font-size: 30px;
		color: var(--palette-primary-main);
	}
	.MuiTabScrollButton-root {
		@media only screen and (min-device-width:640px) {
			height: 33px;
			margin-top: 8px;
			border-radius: 3px;
			&.Mui-disabled {
				display: none;
			}
		}
		@media only screen and (min-width:640px) {
			height: 33px;
			margin-top: 8px;
			border-radius: 3px;
			&.Mui-disabled {
				display: none;
			}
		}

	}
}
