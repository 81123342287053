.container-questions-grid {
	// border: 1px var(--palette-primary-A100) solid;
	@media only screen and (max-width: 568px) {
		border: 0;
	}
	.MuiGrid-grid-md-6,
	.MuiGrid-grid-sm-6,
	.MuiGrid-grid-lg-6 {
		padding: 12px 20px 7px 0;
		position: relative;
		&:after {
			content: "";
			width: calc(100% - 20px);
			height: 2px;
			background-color: var(--palette-primary-A100);
			position: absolute;
			top: 0;
			left: 0;
		}
		@media only screen and (max-width: 568px) {
			padding: 10px 0;
			display: flex;
			width: 100%;
			&:after {
				display: none;
			}
		}
	}
	.MuiTypography-root {
		margin-bottom: 0 !important;
	}
	+ .container-questions-grid {
		border-top: 0;
		border-bottom: 0;
	}
	.MuiIconButton-root.MuiCheckbox-root {
		margin-bottom: 0 !important;
	}
}

.container-questions-range-header {
	width: 100%;
	padding-bottom: 20px;
	.MuiGrid-grid-md-6:first-child,
	.MuiGrid-grid-sm-6:first-child,
	.MuiGrid-grid-lg-6:first-child {
		padding-right: 20px;
	}
}

.container-questions-range {
	padding-bottom: 20px;
	.MuiGrid-grid-md-6:first-child,
	.MuiGrid-grid-sm-6:first-child,
	.MuiGrid-grid-lg-6:first-child {
		padding-right: 20px;
	}
}

.container-radiogroup-grid {
	width: 100%;
	> .MuiFormGroup-root {
		width: 100%;
		> .MuiFormGroup-root {
			width: 100%;
			display: flex;
			@media screen and (min-width: 960px) {
				> .MuiFormControlLabel-root,
				> .MuiGrid-root {
					display: flex;
					width: 100%;
					padding: 0 !important;
					margin-right: 0;
				}
			}
		}
	}
}
// Admin question
.admin-question-container {
	border: 2px #ddd solid;
	padding: 20px;
	margin-bottom: 32px;
	flex-direction: column;
	display: flex;
	.fileUploaderWrapper {
		padding: 3px 0 10px;
	}
}
.admin-question-container-50 {
	display: flex;
	flex-direction: row !important;
	margin: 0 -10px;
	width: calc(100% + 20px) !important;
}

.admin-question-container .MuiGrid-grid-sm-6,
.admin-question-container-50 > div {
	max-width: 50%;
	padding: 9px 10px 2px;
	flex: 0 0 50%;
	position: relative;
	&::before {
		content: "";
		height: 2px;
		width: calc(100% - 20px);
		background-color: #ddd;
		position: absolute;
		top: 2px;
		left: 10px;
	}
	.MuiButtonBase-root.MuiRadio-root + .MuiFormControlLabel-label {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.MuiRadio-root {
		padding-top: 2px !important;
	}
	.radio-select-question-wrapper {
		margin-top: 0 !important;
	}
	h6 {
		margin-bottom: 0 !important;
	}
}
.admin-question-container .range-component .MuiGrid-grid-sm-6 {
	padding: 5px 0;
	&::before {
		display: none;
	}
}
.survey-viewer {
	.admin-question-container {
		border: none;
		padding: 0;
		margin-bottom: 32px;
		flex-direction: row;
		display: flex;
	}
	.admin-question-container-50 {
		display: block;
		margin: 0;
		width: 100% !important;
	}

	.admin-question-container .MuiGrid-grid-sm-6,
	.admin-question-container-50 > div {
		max-width: 100%;
		padding: 0 0 10px;
		flex: 0 0 100%;
		position: relative;
		&::before {
			display: none;
		}
	}
}
.block-archived-questions {
	.admin-question-container {
		border: none;
		padding: 0;
		margin-bottom: 32px;
		flex-direction: row;
		display: flex;
	}
	.admin-question-container .MuiGrid-grid-sm-6,
	.admin-question-container-50 > div {
		&::before {
			display: none;
		}
	}
}
.admin-question-description {
	padding: 40px 32px;
	background-color: var(--palette-primary-A200);
	margin: 0 0 32px;
	.MuiTypography-subtitle1 {
		font-size: 18px;
		margin-bottom: 16px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	.MuiTypography-subtitle2 {
		font-size: 18px;
		letter-spacing: 1px;
	}
}
.admin-question-instruction {
	margin: 0 0 16px !important;
	.MuiTypography-subtitle2 {
		margin: 0 !important;
	}
}

.admin-question-title {
	margin-bottom: 10px !important;
	.MuiTypography-subtitle1 {
		font-size: 17px;
		letter-spacing: 1px;
		color: #011020 !important;
		font-weight: 700;
		margin-bottom: 0;
	}
}

